<script>
import PosterService from '../services/PosterService'

export default {
  name: 'AmazonCustomExport',
  
  props: {
    posterId: {
      type: String,
      required: true
    }
  },

  data: () => ({
    showPreview: false,
    previewImage: null,
    clippingMask: null,
    briefingData: null,
    isLoading: false,
    activeTab: 0,
    canvasRef: null,
    ctx: null,
    zipUrl: null
  }),

  mounted() {
    // Initial zeichnen, falls Daten bereits vorhanden
    if (this.briefingData && this.previewImage) {
      this.drawBriefingCanvas()
    }
  },

  methods: {
    downloadImage(imageUrl, filename) {
      const link = document.createElement('a')
      link.href = imageUrl
      link.download = filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    drawBriefingCanvas() {
      if (!this.$refs.canvasRef || !this.briefingData || !this.previewImage) return

      const canvas = this.$refs.canvasRef
      this.ctx = canvas.getContext('2d')
      
      // Canvas zurücksetzen
      this.ctx.clearRect(0, 0, 400, 400)
      
      // Hintergrundbild laden und zeichnen
      const img = new Image()
      img.onload = () => {
        this.ctx.globalAlpha = 0.3
        this.ctx.drawImage(img, 0, 0, 400, 400)
        this.ctx.globalAlpha = 1.0
        
        // Briefing-Elemente zeichnen
        if (this.briefingData.customizableElements) {
          this.briefingData.customizableElements.forEach(element => {
            // Zeichne einen Rahmen um das Element
            this.ctx.strokeStyle = element.type === 'image' ? '#FF0000' : '#0000FF'
            this.ctx.lineWidth = 2
            
            // Berechne die Position und Größe relativ zur Canvas-Größe
            const x = (element.canvasX / 400) * canvas.width
            const y = (element.canvasY / 400) * canvas.height
            const width = (element.width / 400) * canvas.width
            const height = (element.height / 400) * canvas.height
            
            this.ctx.strokeRect(x, y, width, height)
            
            // Füge Label hinzu
            this.ctx.fillStyle = '#000000'
            this.ctx.font = '12px Arial'
            this.ctx.fillText(element.label || element.type, x, y - 5)
            
            // Wenn es ein Text-Element ist, zeige Platzhalter
            if (element.type === 'text' && element.properties.placeholder) {
              this.ctx.fillStyle = '#666666'
              this.ctx.font = '10px Arial'
              this.ctx.fillText(element.properties.placeholder, x + 5, y + height / 2)
            }
            
            // Wenn es ein Bild-Element ist und einen Clip-Path hat
            if (element.type === 'image' && element.clipPath) {
              this.ctx.strokeStyle = '#FF00FF'
              this.ctx.setLineDash([5, 5])
              this.ctx.strokeRect(x - 2, y - 2, width + 4, height + 4)
              this.ctx.setLineDash([])
            }
          })
        }
      }
      img.src = this.previewImage
    },

    async generateAmazonCustom() {
      try {
        this.isLoading = true
        const response = await PosterService.generateAmazonCustom(this.posterId)
        
        this.previewImage = response.data.preview
        this.clippingMask = response.data.clippingMask
        this.briefingData = response.data.briefing
        this.zipUrl = response.data.zipUrl
        
        this.showPreview = true
        
        // Warte auf das nächste Tick und stelle sicher, dass das Canvas gezeichnet wird
        await this.$nextTick()
        this.drawBriefingCanvas()
        
        // Zeichne erneut nach einer kurzen Verzögerung (für den Fall, dass Bilder noch laden)
        setTimeout(() => {
          this.drawBriefingCanvas()
        }, 500)
      } catch (error) {
        console.error('Fehler beim Generieren des Amazon Custom Exports:', error)
        this.$root.$emit('show-snackbar', {
          text: 'Fehler beim Generieren des Exports',
          color: 'error'
        })
      } finally {
        this.isLoading = false
      }
    }
  },

  watch: {
    activeTab(newVal) {
      if (newVal === 2) { // Briefing Tab
        this.$nextTick(() => {
          this.drawBriefingCanvas()
        })
      }
    },
    
    // Überwache Änderungen an den Daten
    briefingData: {
      handler() {
        if (this.briefingData && this.previewImage) {
          this.$nextTick(() => {
            this.drawBriefingCanvas()
          })
        }
      },
      deep: true
    },
    
    previewImage() {
      if (this.briefingData && this.previewImage) {
        this.$nextTick(() => {
          this.drawBriefingCanvas()
        })
      }
    }
  }
}
</script>

<template>
  <div class="amazon-custom-export">
    <v-btn
      fab
      large
      dark
      color="primary"
      :loading="isLoading"
      @click="generateAmazonCustom"
    >
      <v-icon>mdi-amazon</v-icon>
    </v-btn>
    
    <v-dialog
      v-model="showPreview"
      width="80%"
      scrollable
    >
      <v-card>
        <v-card-title>
          Amazon Custom Export Vorschau
          <v-spacer></v-spacer>
          <v-btn
            icon
            class="mr-2"
            :loading="isLoading" 
            @click="generateAmazonCustom"
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="showPreview = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-tabs v-model="activeTab">
            <v-tab>Vorschau</v-tab>
            <v-tab>Schnittmaske</v-tab>
            <v-tab>Briefing</v-tab>
          </v-tabs>

          <v-window v-model="activeTab">
            <!-- Preview Tab -->
            <v-window-item>
              <v-card flat>
                <v-card-text class="text-center">
                  <v-img
                    v-if="previewImage"
                    :src="previewImage"
                    contain
                    max-height="800"
                    class="preview-image"
                  ></v-img>
                  <v-btn
                    v-if="previewImage"
                    color="primary"
                    class="mt-4"
                    @click="downloadImage(previewImage, 'preview.png')"
                  >
                    <v-icon left>mdi-download</v-icon>
                    Vorschau herunterladen
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-window-item>

            <!-- Clipping Mask Tab -->
            <v-window-item>
              <v-card flat>
                <v-card-text class="text-center">
                  <v-img
                    v-if="clippingMask"
                    :src="clippingMask"
                    contain
                    max-height="800"
                    class="preview-image"
                  ></v-img>
                  <v-btn
                    v-if="clippingMask"
                    color="primary"
                    class="mt-4"
                    @click="downloadImage(clippingMask, 'mask.png')"
                  >
                    <v-icon left>mdi-download</v-icon>
                    Schnittmaske herunterladen
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-window-item>

            <!-- Briefing Tab -->
            <v-window-item>
              <v-card flat>
                <v-card-text>
                  <div class="d-flex flex-column">
                    <div class="briefing-preview mb-4">
                      <canvas
                        ref="canvasRef"
                        width="400"
                        height="400"
                        class="briefing-canvas"
                      ></canvas>
                    </div>
                    <div class="briefing-content">
                      <pre>{{ briefingData }}</pre>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-window-item>
          </v-window>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="zipUrl"
            color="primary"
            :href="zipUrl"
            target="_blank"
            class="mr-2"
          >
            <v-icon left>mdi-archive</v-icon>
            ZIP herunterladen
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="showPreview = false"
          >
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.amazon-custom-export {
  display: inline-block;
}

.preview-image {
  border: 1px solid #e0e0e0;
  background: repeating-conic-gradient(#808080 0% 25%, transparent 0% 50%) 
    50% / 20px 20px;
}

.briefing-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  border-radius: 8px;
  padding: 1rem;
}

.briefing-canvas {
  border: 1px solid #e0e0e0;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.briefing-content {
  background: #000;
  border-radius: 8px;
}

.title {
  color: #1976d2;
  font-weight: 500;
}

.subtitle-1 {
  color: #424242;
  font-weight: 500;
}

.v-list-item-subtitle {
  color: #616161;
}

.v-expansion-panels {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.v-expansion-panel {
  background: #000;
  margin-bottom: 8px;
}

.v-expansion-panel::before {
  box-shadow: none;
}

.v-expansion-panel-header {
  padding: 16px;
}

.v-expansion-panel-content {
  padding: 16px;
}

.v-list {
  background: transparent;
  padding: 0;
}

.v-list-item {
  padding: 12px 0;
}

.v-divider {
  margin: 8px 0;
}
</style> 