//Poster service
//This service is responsible for all poster related requests
import Api from '@/services/Api'

export default {
    /**
     * Get all posters
     * @returns {Promise}
     **/
    getPosters() {
        return Api().get('posters')
    },

    /**
     * Get a single poster
     * @param {String} id
     * @returns {Promise}
     **/
    getPoster(id) {
        return Api().get(`poster/${id}`)
    },

    /**
     * Generate Amazon Custom Data
     * @param {String} posterId
     * @returns {Promise}
     */
    async generateAmazonCustom(posterId) {
        return Api().post(`poster/${posterId}/amazon-custom`)
    },  

    /**
     * Create a new poster
     * @param {Object} payload
     * @returns {Promise}
     **/
    createPoster(payload) {
        return Api().post('poster/create', payload)
    },

    /**
     * Update a poster
     * @param {String} id
     * @param {Object} payload
     * @returns {Promise}
     **/
    updatePoster(id, payload) {
        return Api().put(`poster/${id}`, payload)
    },

    /**
     * Delete a poster
     * 
     * @param {String} id
     * @returns {Promise}
     **/
    deletePoster(id) {
        return Api().delete(`poster/${id}`)
    },

    /**
     * Duplicate a poster
     * @param {String} id
     * @returns {Promise}
     **/
    duplicatePoster(id, sku, title) {
        return Api().post(`poster/${id}/duplicate`, { SKU: sku, title: title })
    },


    /**
     * Get a preview of a poster
     * @param {String} id
     * @param {String} size
     * @returns {Promise}
     **/
    getPosterPreview(id) {
        return Api().get(`poster/${id}/preview`)
    },

    getFonts() {
        return Api().get('fonts')
    },

    fixImage(s3url) {
        return Api().post('/fix/s3Image', { url: s3url })
    },

    clearCache() {
        return Api().post('/cache/clear')
    },

    /**
     * Lädt ein Shape für einen Poster hoch
     * @param {string} posterId - Die ID des Posters
     * @param {string} shapeData - Das SVG des Shapes
     * @returns {Promise}
     */
    uploadShape(posterId, shapeData) {
        return Api().post(`poster/${posterId}/shape`, { shape: shapeData });
    }


}