//import { parse } from "svg-parser"

import convert from "xml-js"

class SVGParser {

    constructor(svgString) {
        this.svgString = svgString;
        this.parser = new DOMParser();
        this.svgDoc = this.parser.parseFromString(svgString, "image/svg+xml");
        this.svgRoot = this.svgDoc.documentElement;
        this.debugLog = [];

        // Event-Listener für CMD+X
        document.addEventListener('keydown', (e) => {
            if (e.metaKey && e.key === 'x') {
                console.log(this.getDebugLog());
            }
        });

        this.addDebugLog("SVG Parser initialisiert", {
            svgRootName: this.svgRoot?.nodeName,
            hasNamespace: !!this.svgRoot?.getAttribute('xmlns'),
            parseErrors: Array.from(this.svgDoc.getElementsByTagName('parsererror')).map(e => e.textContent),
            documentType: this.svgDoc.doctype?.name || 'none',
            contentType: this.svgDoc.contentType,
            rootAttributes: this.svgRoot ? Object.fromEntries(
                Array.from(this.svgRoot.attributes)
                    .map(attr => [attr.name, attr.value])
            ) : {}
        });

        // Initialisiere die Dimensionen beim Konstruktor
        this.dimensions = this.extractDimensions();

        // Initialisiere die Arrays für Elemente
        this.textElements = [];
        this.imageElements = [];
        this.genericElements = [];
        this.idCounter = 0;

        // Verarbeite das SVG
        this.process();
    }

    extractDimensions() {
        try {
            if (!this.svgRoot) {
                this.addDebugLog("Fehler: Kein SVG Root Element gefunden");
                return { width: 800, height: 600 }; // Standardwerte
            }

            // Versuche zuerst die viewBox zu lesen
            const viewBox = this.svgRoot.getAttribute('viewBox');
            if (viewBox) {
                const [, , width, height] = viewBox.split(' ').map(Number);
                if (width && height) {
                    this.addDebugLog("Dimensionen aus viewBox extrahiert", { width, height });
                    return { width, height };
                }
            }

            // Versuche width/height Attribute zu lesen
            let width = this.svgRoot.getAttribute('width');
            let height = this.svgRoot.getAttribute('height');

            // Entferne px, pt oder andere Einheiten
            width = width ? parseFloat(width.replace(/[^0-9.]/g, '')) : 0;
            height = height ? parseFloat(height.replace(/[^0-9.]/g, '')) : 0;

            this.addDebugLog("Dimensionen aus Attributen extrahiert", { 
                originalWidth: this.svgRoot.getAttribute('width'),
                originalHeight: this.svgRoot.getAttribute('height'),
                parsedWidth: width,
                parsedHeight: height
            });

            // Fallback zu Standardwerten
            width = width || 800;
            height = height || 600;

            return { width, height };
        } catch (error) {
            this.addDebugLog("Fehler beim Extrahieren der Dimensionen", {
                error: error.message,
                fallbackDimensions: { width: 800, height: 600 }
            });
            return { width: 800, height: 600 };
        }
    }

    getDimensions() {
        return this.dimensions;
    }

    addImageElement(attributes = {}) {
        const id = `Image-${this.getId()}`;

        const imageElement = {
            type: "element",
            name: "image",
            attributes: {
                id: id,
                x: "100",
                y: "100",
                width: "200",
                height: "200",
                style: "fill: #ffffff;",
                preserveAspectRatio: "none",
                'xlink:href': '',
                ...attributes
            }
        };

        // Create a clean copy without Vue reactivity
        const cleanElement = JSON.parse(JSON.stringify(imageElement));
        this.imageElements.push(cleanElement);

        return cleanElement;
    }

    setSvgFile(svgFile) {
        this.data = svgFile;
        this.process();
    }

    process() {
        if (!this.svgString) {
            this.addDebugLog("Fehler: Kein SVG-String vorhanden");
            return;
        }

        try {
            this.addDebugLog("Start SVG Verarbeitung");
            
            // Reset arrays before processing
            this.textElements = [];
            this.imageElements = [];
            this.genericElements = [];

            // SVG String bereinigen
            let cleanSvg = this.svgString.trim();

            console.log(this.svgString);
            
            // Entferne DOCTYPE falls vorhanden
            cleanSvg = cleanSvg.replace(/<!DOCTYPE[^>]*>/i, '');
            
            // Stelle sicher, dass es ein SVG-Element ist
            if (!cleanSvg.includes('<svg')) {
                this.addDebugLog("Fehler: Kein SVG-Element gefunden");
                return;
            }

            // Namespace-Korrektur
            if (!cleanSvg.includes('xmlns="http://www.w3.org/2000/svg"')) {
                cleanSvg = cleanSvg.replace('<svg', '<svg xmlns="http://www.w3.org/2000/svg"');
                this.addDebugLog("SVG Namespace hinzugefügt");
            }

            // xlink Namespace hinzufügen falls nicht vorhanden
            if (!cleanSvg.includes('xmlns:xlink')) {
                cleanSvg = cleanSvg.replace('<svg', '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
                this.addDebugLog("xlink Namespace hinzugefügt");
            }

            // XML-Deklaration hinzufügen falls nicht vorhanden
            if (!cleanSvg.startsWith('<?xml')) {
                cleanSvg = '<?xml version="1.0" encoding="UTF-8"?>' + cleanSvg;
                this.addDebugLog("XML-Deklaration hinzugefügt");
            }

            this.svgString = cleanSvg;
            
            // Aktualisiere den DOM Parser
            this.svgDoc = this.parser.parseFromString(this.svgString, "image/svg+xml");
            this.svgRoot = this.svgDoc.documentElement;

            // Überprüfe auf Parse-Fehler
            const parseErrors = Array.from(this.svgDoc.getElementsByTagName('parsererror'));
            if (parseErrors.length > 0) {
                this.addDebugLog("SVG Parse-Fehler gefunden", {
                    errors: parseErrors.map(e => e.textContent)
                });
            }

            const xmlJSON = convert.xml2js(this.svgString, {
                compact: false,
                spaces: 2,
                ignoreComment: true,
                alwaysChildren: true,
                attributeNameFn: name => name.replace(/^xlink:/, '')  // Bereinige xlink Präfix
            });

            if (!xmlJSON || !xmlJSON.elements || !xmlJSON.elements[0]) {
                this.addDebugLog("Fehler: Ungültiges SVG-Format");
                return;
            }

            this.addDebugLog("XML zu JSON konvertiert");

            // Verbesserte Element-Erkennung
            const allElements = this.findAllElements(xmlJSON.elements[0]);
            this.addDebugLog("Elemente gefunden", {
                textElements: allElements.text.length,
                imageElements: allElements.image.length,
                otherElements: allElements.other.length
            });

            this.normalizeStructure(xmlJSON);
            this.processChilds(xmlJSON.elements[0]);

            this.data = convert.js2xml(xmlJSON);
            this.addDebugLog("Verarbeitung abgeschlossen");

        } catch (error) {
            this.addDebugLog("Fehler bei der Verarbeitung", {
                error: error.message,
                stack: error.stack
            });
            this.data = this.svgString;
        }
    }

    findAllElements(node) {
        this.addDebugLog("Starte Elementsuche");
        let elements = {
            text: [],
            image: [],
            other: []
        };

        const processedIds = new Set();
        let elementCounts = { 
            total: 0, 
            text: 0, 
            image: 0, 
            other: 0,
            withTransform: 0,
            withStyle: 0,
            withId: 0,
            tspanCount: 0
        };

        const processNode = (node) => {
            if (!node || !node.type) return;

            if (node.type === 'element') {
                elementCounts.total++;
                
                // Verbesserte Element-Erkennung
                const isText = node.name === 'text' || node.elements?.some(e => e.name === 'text');
                const isImage = node.name === 'image';
                
                if (isText) elementCounts.text++;
                if (isImage) elementCounts.image++;
                if (!isText && !isImage) elementCounts.other++;

                if (node.attributes?.transform) elementCounts.withTransform++;
                if (node.attributes?.style) elementCounts.withStyle++;
                if (node.attributes?.id) elementCounts.withId++;
                if (node.name === 'tspan') elementCounts.tspanCount++;

                // Element zur entsprechenden Liste hinzufügen
                if (isText) {
                    elements.text.push(node);
                } else if (isImage) {
                    elements.image.push(node);
                } else {
                    elements.other.push(node);
                }

                if (node.attributes && node.attributes.id) {
                    processedIds.add(node.attributes.id);
                    this.addDebugLog(`Element gefunden`, {
                        id: node.attributes.id,
                        type: node.name,
                        isText,
                        isImage,
                        hasStyle: !!node.attributes.style,
                        hasTransform: !!node.attributes.transform,
                        transformValue: node.attributes.transform,
                        childElements: node.elements?.length || 0,
                        parentType: node.parent?.name || 'none',
                        nestedLevel: this.calculateNestingLevel(node)
                    });
                }
            }

            // Rekursive Verarbeitung von Kindelementen
            if (node.elements) {
                node.elements.forEach(child => {
                    child.parent = node; // Setze Parent-Referenz für Verschachtelungstiefe
                    processNode(child);
                });
            }
        };

        processNode(node);

        this.addDebugLog("Elementsuche abgeschlossen", {
            elementCounts,
            uniqueIds: processedIds.size,
            duplicateIds: elementCounts.total - processedIds.size,
            nestingDepth: this.calculateMaxNestingDepth(node)
        });

        return elements;
    }

    calculateNestingLevel(node) {
        let level = 0;
        let current = node;
        while (current.parent) {
            level++;
            current = current.parent;
        }
        return level;
    }

    calculateMaxNestingDepth(node) {
        if (!node.elements) return 0;
        return 1 + Math.max(0, ...node.elements.map(child => this.calculateMaxNestingDepth(child)));
    }

    extractTextFromElement(node) {
        const extractText = (element) => {
            let text = '';
            if (element.text) {
                text += element.text;
            }
            if (element.elements) {
                element.elements.forEach(child => {
                    if (child.type === 'text') {
                        text += child.text || '';
                    } else {
                        text += extractText(child);
                    }
                });
            }
            return text;
        };

        return extractText(node);
    }

    normalizeStructure(xmlJSON) {
        if (!xmlJSON || !xmlJSON.elements || !xmlJSON.elements[0]) return;

        const svgRoot = xmlJSON.elements[0];

        // Prüfe auf Adobe Illustrator spezifische Struktur
        const isIllustrator = this.isIllustratorSVG(svgRoot);

        if (isIllustrator) {
            console.log("IS ILLUSTRATOR");
            // Konvertiere Illustrator-spezifische Stile
            this.convertIllustratorStyles(svgRoot);
        }

        // Stelle sicher, dass alle erforderlichen Namespaces vorhanden sind
        if (!svgRoot.attributes) svgRoot.attributes = {};
        if (!svgRoot.attributes['xmlns:xlink']) {
            svgRoot.attributes['xmlns:xlink'] = 'http://www.w3.org/1999/xlink';
        }
    }

    convertIllustratorStyles(element) {
        try {
            if (!element) return;

            // Konvertiere Klassen-basierte Stile zu Inline-Stilen
            if (element.attributes && element.attributes.class) {
                const className = element.attributes.class;
                // Extrahiere Stile aus der Klasse und konvertiere sie zu Inline-Stilen
                const styles = this.getIllustratorStyles(className);
                if (styles) {
                    const existingStyle = element.attributes.style || '';
                    element.attributes.style = existingStyle + styles;
                }
                delete element.attributes.class;
            }

            // Rekursiv für alle Kindelemente
            if (element.elements) {
                element.elements.forEach(child => {
                    try {
                        this.convertIllustratorStyles(child);
                    } catch (childError) {
                        console.error('Fehler bei der Konvertierung von Kind-Stilen:', childError);
                    }
                });
            }
        } catch (error) {
            console.error('Fehler bei der Stil-Konvertierung:', error);
        }
    }

    getIllustratorStyles(className) {
        try {
            if (!this.styleMap) {
                this.styleMap = this.parseStyleTag();
            }
            return this.styleMap[className] || '';
        } catch (error) {
            console.error('Fehler beim Abrufen der Illustrator-Stile:', error);
            return '';
        }
    }

    parseStyleTag() {
        const styleMap = {};
        try {
            // Finde das style-Element im SVG
            const styleElement = this.svgRoot.querySelector('style');
            if (!styleElement) {
                console.log('Kein Style-Element gefunden');
                return styleMap;
            }

            // Parse den CSS-Text
            const cssText = styleElement.textContent;
            if (!cssText) {
                console.log('Kein CSS-Text gefunden');
                return styleMap;
            }

            // Teile den CSS-Text in Regeln
            const cssRules = cssText.split('}');
            cssRules.forEach(rule => {
                if (!rule.trim()) return;

                const parts = rule.split('{');
                if (parts.length !== 2) return;

                const [selector, properties] = parts;
                // Bereinige den Selektor (entferne Punkte und Leerzeichen)
                const className = selector.trim().replace('.', '');
                // Bereinige die Eigenschaften
                const styles = properties.trim();

                if (className && styles) {
                    styleMap[className] = styles;
                }
            });

            console.log('Parsed style map:', styleMap);
            return styleMap;
        } catch (error) {
            console.error('Fehler beim Parsen des Style-Tags:', error);
            return styleMap;
        }
    }

    processChilds(node) {
        if (!node.elements) return;

        node.elements.forEach(element => {
            if (element.type === 'element') {
                switch (element.name) {
                    case 'text':
                        this.normalizeTextElement(element);
                        break;
                    case 'image':
                        this.normalizeImageElement(element);
                        break;
                    default:
                        // Füge generische Elemente zur Liste hinzu
                        if (element.attributes && element.attributes.id) {
                            // Entferne parent-Referenzen bevor das Element gespeichert wird
                            const cleanElement = this.removeParentReferences(element);
                            this.genericElements.push(cleanElement);
                        }
                        break;
                }
            }
            this.processChilds(element);
        });
    }

    removeParentReferences(element) {
        // Erstelle eine tiefe Kopie des Elements ohne parent-Referenzen
        const cleanElement = { ...element };
        delete cleanElement.parent;

        if (cleanElement.elements) {
            cleanElement.elements = cleanElement.elements.map(child => {
                const cleanChild = { ...child };
                delete cleanChild.parent;
                // Rekursiv für verschachtelte Elemente
                if (cleanChild.elements) {
                    cleanChild.elements = cleanChild.elements.map(grandChild => {
                        const cleanGrandChild = { ...grandChild };
                        delete cleanGrandChild.parent;
                        return cleanGrandChild;
                    });
                }
                return cleanChild;
            });
        }

        return cleanElement;
    }

    normalizeTextElement(element) {
        if (!element.attributes) element.attributes = {};
        if (!element.attributes.id) {
            element.attributes.id = "Text-" + this.getId();
        }

        // Transform zu x,y Koordinaten konvertieren
        if (element.attributes.transform) {
            const pos = this.parseTransform(element.attributes.transform);
            if (pos.translate) {
                element.attributes.x = pos.translate[0].toString();
                element.attributes.y = pos.translate[1].toString();
                delete element.attributes.transform;
                this.addDebugLog("Transform zu Koordinaten konvertiert", {
                    id: element.attributes.id,
                    x: element.attributes.x,
                    y: element.attributes.y
                });
            }
        }

        // Extrahiere Text aus allen tspans und entferne sie
        const textContent = this.extractTextFromElement(element);
        
        // Entferne alle tspan-Elemente und parent-Referenzen
        if (element.elements) {
            element.elements = [{
                type: "text",
                text: textContent
            }];
        }

        // Füge den Text direkt zum Text-Element hinzu
        element.elements = [{
            type: "text",
            text: textContent
        }];

        this.addDebugLog("Text-Element bereinigt", {
            id: element.attributes.id,
            text: textContent,
            position: {
                x: element.attributes.x,
                y: element.attributes.y
            }
        });

        // Extrahiere und kombiniere Stile
        let styles = {};
        
        // Verarbeite inline Stile
        if (element.attributes.style) {
            const styleAttrs = element.attributes.style.split(';').filter(s => s.trim());
            styleAttrs.forEach(style => {
                const [key, value] = style.split(':').map(s => s.trim());
                if (key && value) {
                    if (key === 'font-family') {
                        styles[key] = this.extractFontFamily(value);
                    } else {
                        styles[key] = value;
                    }
                }
            });
        }

        // Verarbeite direkte Attribute
        if (element.attributes['font-family']) {
            styles['font-family'] = this.extractFontFamily(element.attributes['font-family']);
            delete element.attributes['font-family'];
        }
        if (element.attributes['font-size']) {
            styles['font-size'] = element.attributes['font-size'];
            delete element.attributes['font-size'];
        }

        // Kombiniere alle Stile zu einem style-String
        const combinedStyles = Object.entries(styles)
            .map(([key, value]) => `${key}: ${value}`)
            .join('; ');
        
        if (combinedStyles) {
            element.attributes.style = combinedStyles;
        }

       
        if (!element.attributes['dominant-baseline']) {
            element.attributes['dominant-baseline'] = 'central';
        }

        // Extrahiere Font-Size für Dimensionsberechnung
        let fontSize = 12;
        if (styles['font-size']) {
            fontSize = parseFloat(styles['font-size'].replace(/[^0-9.]/g, ''));
        }

        // Setze Dimensionen
        element.attributes.maxwidth = element.attributes.maxwidth || (textContent.length * fontSize * 0.7).toString();
        element.attributes.maxheight = element.attributes.maxheight || (fontSize * 1.2).toString();
        element.attributes.fontsize = fontSize.toString();

        this.textElements.push(element);
    }

    normalizeImageElement(element) {
        if (!element.attributes) element.attributes = {};
        if (!element.attributes.id) {
            element.attributes.id = "Image-" + this.getId();
        }

        // Existierende href-Werte sichern
        const existingHref = element.attributes['href'] || element.attributes['xlink:href'] || '';
        const existingS3url = element.attributes.s3url || '';

        // Standardattribute mit den existierenden href-Werten
        const defaultAttributes = {
            preserveAspectRatio: "none",
            style: "fill: #ffffff;",
            'xlink:href': existingS3url || existingHref,
            'href': existingS3url || existingHref
        };

        // Zusammenführen der Attribute, wobei existierende nicht überschrieben werden
        element.attributes = { ...defaultAttributes, ...element.attributes };
        
        this.imageElements.push(element);
    }

    findElement(child, id) {
        if (child.attributes && child.attributes.id == id) {
            return child;
        }
        if (child.elements) {
            for (let c of child.elements) {
                let found = this.findElement(c, id);
                if (found) return found;
            }
        }
        return null;
    }


    updateSVG(svgData) {
        this.data = svgData;
        const xmlJSON = convert.xml2js(this.data);

        // Update image elements
        for (let imageElement of this.imageElements) {
            const id = imageElement.attributes.id;
            const newImageElement = this.findElement(xmlJSON, id);

            if (newImageElement) {
                // Save old values we want to preserve
                const oldHref = imageElement.attributes['xlink:href'];
                const oldPreserveAspectRatio = imageElement.attributes.preserveAspectRatio;

                // Update all attributes
                imageElement.attributes = { ...newImageElement.attributes };

                // Restore preserved values if they were lost
                if (oldHref && !imageElement.attributes['xlink:href']) {
                    imageElement.attributes['xlink:href'] = oldHref;
                }
         
                if (oldPreserveAspectRatio && !imageElement.attributes.preserveAspectRatio) {
                    imageElement.attributes.preserveAspectRatio = oldPreserveAspectRatio;
                }
            }
        }

        // Update other elements as before
        for (let textElement of this.textElements) {
            const id = textElement.attributes.id;
            const newTextElement = this.findElement(xmlJSON, id);
            if (newTextElement) {
                textElement.attributes = newTextElement.attributes;
            }
        }

        for (let genericElement of this.genericElements) {
            const id = genericElement.attributes.id;
            const newGenericElement = this.findElement(xmlJSON, id);
            if (newGenericElement) {
                genericElement.attributes = newGenericElement.attributes;
            }
        }
    }

    renameNodeId(oldId, newId) {
        //this function should rename a node id and update all references to it
        //first find the node

        //now update all references to it
        for (let textElement of this.textElements) {
            if (textElement.attributes.id == oldId) {
                textElement.attributes.id = newId;
            }
        }
        for (let imageElement of this.imageElements) {
            if (imageElement.attributes.id == oldId) {
                imageElement.attributes.id = newId;
            }
        }

        for (let genericElement of this.genericElements) {
            if (genericElement.attributes.id == oldId) {
                genericElement.attributes.id = newId;
            }
        }

    }

    getId() {
        this.idCounter++;
        return this.idCounter
    }

    getTextElements() {
        try {
            // Entferne parent-Referenzen vor der Rückgabe
            return this.textElements.map(element => this.removeParentReferences(element));
        } catch (error) {
            console.error('Fehler beim Extrahieren der Text-Elemente:', error);
            return [];
        }
    }

    getImageElements() {
        try {
            // Entferne parent-Referenzen vor der Rückgabe
            return this.imageElements.map(element => this.removeParentReferences(element));
        } catch (error) {
            console.error('Fehler beim Extrahieren der Bild-Elemente:', error);
            return [];
        }
    }

    getGenericElements() {
        // Entferne parent-Referenzen vor der Rückgabe
        return this.genericElements.map(element => this.removeParentReferences(element));
    }

    getElementAttributes(element) {
        const attributes = {};
        Array.from(element.attributes).forEach(attr => {
            attributes[attr.name] = attr.value;
        });
        return attributes;
    }

    getElementIds() {
        let ids = [];
        for (let textElement of this.textElements) {
            ids.push(textElement.attributes.id);
        }
        for (let imageElement of this.imageElements) {
            ids.push(imageElement.attributes.id);
        }
        for (let genericElement of this.genericElements) {
            ids.push(genericElement.attributes.id);
        }

        return ids;
    }

    getJson() {
        return this.json;
    }

    deleteElement(id) {
        // Suche und entferne das Element aus allen Arrays
        this.textElements = this.textElements.filter(element => element.attributes.id !== id);
        this.imageElements = this.imageElements.filter(element => element.attributes.id !== id);
        this.genericElements = this.genericElements.filter(element => element.attributes.id !== id);

        // Logge die Löschung
        this.addDebugLog("Element gelöscht", {
            id: id,
            remainingElements: {
                text: this.textElements.length,
                image: this.imageElements.length,
                generic: this.genericElements.length
            }
        });

        // Aktualisiere die SVG-Datei
        const xmlJSON = convert.xml2js(this.svgString, {
            compact: false,
            spaces: 2,
            ignoreComment: true,
            alwaysChildren: true
        });

        // Rekursive Funktion zum Entfernen des Elements aus dem XML-Baum
        const removeElementById = (node) => {
            if (!node.elements) return true;
            
            node.elements = node.elements.filter(element => {
                if (element.attributes && element.attributes.id === id) {
                    return false; // Element entfernen
                }
                return removeElementById(element); // Rekursiv in Kinder suchen
            });
            
            return true;
        };

        removeElementById(xmlJSON.elements[0]);
        this.data = convert.js2xml(xmlJSON);
        
        return true;
    }

    getSVGFile() {
        return this.data;
    }

    isIllustratorSVG(svgRoot) {
        const result = {
            isIllustrator: false,
            reasons: []
        };

        // Methode 1: Prüfe auf Generator-Kommentar
        const hasIllustratorComment = svgRoot.elements?.some(el =>
            el.type === 'comment' && el.comment?.includes('Adobe Illustrator')
        );
        if (hasIllustratorComment) {
            result.isIllustrator = true;
            result.reasons.push('illustrator_comment');
        }

        // Methode 2: Prüfe auf typische Illustrator-Klassen
        const defs = svgRoot.elements?.find(el => el.name === 'defs');
        const styleElement = defs?.elements?.find(el => el.name === 'style');
        const styleText = styleElement?.elements?.[0]?.text;
        if (styleText && (styleText.includes('.st') || styleText.includes('.cls'))) {
            result.isIllustrator = true;
            result.reasons.push('illustrator_classes');
        }

        // Methode 3: Prüfe auf typische Illustrator-Attribute
        const hasIllustratorAttrs = svgRoot.elements?.some(el =>
            el.attributes?.class?.startsWith('st') ||
            el.attributes?.class?.startsWith('cls')
        );
        if (hasIllustratorAttrs) {
            result.isIllustrator = true;
            result.reasons.push('illustrator_attributes');
        }

        this.addDebugLog("Illustrator SVG Check", result);
        return result.isIllustrator;
    }

    addDebugLog(message, data = null) {
        const timestamp = new Date().toISOString();
        let logEntry = `[${timestamp}] ${message}`;
        
        if (data) {
            // Entferne Base64 und Pfad-Daten
            const sanitizedData = JSON.stringify(data, (key, value) => {
                if (typeof value === 'string') {
                    if (value.startsWith('data:') || key === 'd' || key === 'path') {
                        return '[REMOVED]';
                    }
                }
                return value;
            }, 2);
            logEntry += `\nData: ${sanitizedData}`;
        }
        
        this.debugLog.push(logEntry);
    }

    getDebugLog() {
        return this.debugLog.join('\n\n');
    }

    parseTransform(transform) {
        try {
            const transforms = {};
            const regex = /(translate|scale|rotate|matrix)\(([-\d\s,.]+)\)/g;
            let match;
            
            while ((match = regex.exec(transform)) !== null) {
                const [, type, values] = match;
                transforms[type] = values.split(/[\s,]+/).map(Number);
            }
            
            return transforms;
        } catch (error) {
            return { error: error.message };
        }
    }

    extractFontFamily(fontFamily) {
        if (!fontFamily) return '';
        
        // Suche nach Text in einfachen oder doppelten Anführungszeichen
        const quotedFontMatch = fontFamily.match(/['"]([^'"]+)['"]/);
        if (quotedFontMatch) {
            return quotedFontMatch[1];
        }
        
        // Wenn keine Anführungszeichen gefunden wurden, nimm den ersten Font-Namen
        return fontFamily.split(',')[0].trim();
    }

}

export default SVGParser;