<template>
  <div style="padding: 15px">
    <div style="position: fixed; top: 0; left: 0" v-if="svgFile">
      <!--<div style="background: #ffffff" v-html="previewSVG"></div>-->
      <Artboard ref="artboard"></Artboard>
    </div>
    <v-row>
      
      <v-col cols="2">

        <!-- Darkmode toggle -->
        <v-switch v-model="darkMode" label="Darkmode" color="primary" @change="darkModeChanged"></v-switch>
        
        <v-card style="position: fixed; height: 90%; left: 0px; overflow: scroll">

          <v-tabs v-model="leftTab">
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab :key="1"> Text Element </v-tab>

            <v-tab :key="2"> Bild Elemente </v-tab>

            <v-tab :key="3"> Andere </v-tab>
          </v-tabs>
          <v-tabs-items v-model="leftTab">
            <v-tab-item :key="1">
              <v-list dense v-if="parser">
                <v-list-item-group v-model="selectedTextItem" color="primary">
                  <v-list-item v-for="item of parser.getTextElements()" :key="item.attributes.id">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon small @click="editId(item.attributes.id)">mdi-pencil</v-icon>
                        {{ item.attributes.id }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>

            </v-tab-item>

            <v-tab-item :key="2">
              <v-list dense v-if="parser">

                  <v-list-item-group v-model="selectedImageItem" color="primary">
                  <!-- Add button at the top -->
                  <v-list-item>
                    <v-list-item-content>
                      <v-btn block color="primary" @click="addNewImageElement">
                        <v-icon left>mdi-plus</v-icon>
                        Neues Bild
                      </v-btn>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-for="item of parser.getImageElements()" :key="item.attributes.id">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon small @click="editId(item.attributes.id)">mdi-pencil</v-icon>
                        {{ item.attributes.id }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-tab-item>

            <v-tab-item :key="3">
              <v-list dense v-if="parser">
                <v-list-item-group v-model="selectedElementItem" color="primary">
                  <v-list-item v-for="item of parser.getGenericElements()" :key="item.attributes.id">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon small @click="editId(item.attributes.id)">mdi-pencil</v-icon>
                        {{ item.attributes.id }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-tab-item>
          </v-tabs-items>


    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>Shape Upload</v-card-title>
          <v-card-text>

            <!-- Aktuller shape darstellen -->
            <div v-if="poster.shape" v-html="poster.shape" style="max-width: 300px; max-height: 300px; overflow: hidden;"></div>

            <v-file-input
              accept=".svg"
              label="Shape SVG hochladen"
              @change="handleShapeUpload"
              :loading="shapeUploading"
              prepend-icon="mdi-shape"
            ></v-file-input>
            <v-alert
              v-if="currentShape"
              type="success"
              text
              class="mt-2"
            >
              Shape wurde erfolgreich hochgeladen
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
        </v-card>
      </v-col>


      <v-col :cols="expandOptions ? 1 : 8"> </v-col>
      <v-col :cols="expandOptions ? 9 : 2">
        <v-card style="position: fixed; height: 90%; right: 0px; overflow: scroll;" :class="expandOptions ? 'expanded' : ''">
          <v-card-title>Ausgewählte Ebene <v-spacer></v-spacer> <v-btn icon @click="expandOptions = !expandOptions">
              <v-icon>{{ expandOptions ? 'mdi-chevron-left' : 'mdi-chevron-right' }}</v-icon>
            </v-btn>
            <v-progress-circular v-show="loading" :width="2" indeterminate>
            </v-progress-circular></v-card-title>
            <span>SKU: {{ poster.SKU }}</span>
          <v-card-text>
            <v-tabs v-model="rightTab">
              <v-tabs-slider color="primary"></v-tabs-slider>

              <v-tab :key="1"> Eigenschaften </v-tab>

              <v-tab :key="2"> Daten </v-tab>

              <v-tab :key="3"> Raw </v-tab>
            </v-tabs>
            <v-tabs-items v-model="rightTab">
              <v-tab-item :key="1">
                <SvgProperties ref="svgProps" />
              </v-tab-item>

              <v-tab-item :key="2">
                <v-btn @click="processOptionSelect()">Aktualisieren</v-btn>
                <DatabaseProps v-if="parser" :poster="poster" :targetIds="elements" :fontSets="fontSets" ref="databaseProps" />
              </v-tab-item>

              <v-tab-item :key="3">
                <pre>{{ selectedTextItemElement }}</pre>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
      
    </v-row>

    <!-- download FAB -->
    <v-btn fab large dark bottom left class="v-btn--example" @click="downloadSVG()" color="primary"
      style="position: fixed; bottom: 10px; left: 10px">
      <v-icon>mdi-download</v-icon>
    </v-btn>

    <v-btn fab large dark bottom left class="v-btn--example" @click="savePoster()" color="primary"
      style="position: fixed; bottom: 10px; right: 10px">
      <v-icon>mdi-content-save</v-icon>
    </v-btn>
    <v-snackbar color="primary" v-model="snackbar" :timeout="2000">
      {{ snackbarMessage }}
    </v-snackbar>

      
      <v-dialog v-model="editIdModal" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Edit ID</span>
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="editedId" label="ID" outlined></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="saveEditedId">Save</v-btn>
            <v-btn color="secondary" @click="editIdModal = false;">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      
    <div id="editedSVG" style="opacity: 0; height: 1px; overflow: hidden"></div>
    <AuthCheck />

    <amazon-custom-export 
      :poster-id="poster._id"
      style="position: fixed; bottom: 10px; left: 100px"
    />

  </div>
</template>

<script>
import SvgProperties from "@/components/svgProperties";
import DatabaseProps from "@/components/dataProperties";
import Artboard from "@/components/Artboard";
import AuthCheck from "@/components/AuthCheck";
import AmazonCustomExport from "@/components/AmazonCustomExport";
import PosterService from "@/services/PosterService";
import FontService from "@/services/FontService";

import SVGParser from "@/core/svgParser";

import EventBus from "@/event-bus";

import $ from "jquery";

export default {
  name: "PosterEditor",
  components: { 
    SvgProperties, 
    Artboard, 
    AuthCheck, 
    DatabaseProps,
    AmazonCustomExport 
  },
  data: () => ({
    uploadDialog: true,
    svgFile: null,
    parser: null,
    activeLayer: null,
    prevSelection: null,
    selectedTextItem: null,
    selectedImageItem: null,
    selectedElementItem: null,

    selectedTextItemElement: null,
    selectedImageItemElement: null,
    selectedElementItemElement: null,
    darkMode: false,
    svgParsed: false,
    loading: false,
    poster: {},
    elements: null,
    fontSets: [],
    snackbar: false,
    snackbarMessage: "",
    rightTab: 0,
    leftTab: 0,

    fonts: [],

    expandOptions: false,

    editIdModal: false,
    editedId: "",
    oldId: null,
    shapeUploading: false,
    currentShape: null

  }),

  watch: {
    selectedTextItem: {
      handler() {
        this.updateSvgProps();
        this.selectedImageItem = null;
        this.selectedElementItem = null;
      },
      deep: true,
    },
    selectedImageItem: {
      handler() {
        this.updateSvgProps();
        this.selectedTextItem = null;
        this.selectedElementItem = null;
      },
      deep: true,
    },
    selectedElementItem: {
      handler() {
        this.updateSvgProps();
        this.selectedImageItem = null;
        this.selectedTextItem = null;
      },
      deep: true,
    },
    parser: {
      handler(newParser) {
        if (newParser && this.$refs.artboard) {
          console.log("Parser aktualisiert, aktualisiere Artboard");
          this.$refs.artboard.updateSVG(
            newParser.getSVGFile(),
            newParser.getTextElements(),
            newParser.getImageElements()
          );
        }
      },
      immediate: true
    },
    svgFile: {
      handler(newSvg) {
        if (newSvg && !this.parser) {
          console.log("SVG-Datei aktualisiert, erstelle neuen Parser");
          this.parser = new SVGParser(newSvg);
        }
      },
      immediate: true
    }
  },

  mounted() {
    this.poster.id = this.$route.query.id;

    this.loadPoster();
    EventBus.$on("SVG_EDITED", this.updateSVG);
    EventBus.$on("fixImage", this.fixImage);

  },

  beforeDestroy() {
    console.log("destroy");
    //Eventbus off
    EventBus.$off("SVG_EDITED", this.updateSVG);
    EventBus.$off("fixImage", this.fixImage);

  },

  unmounted() {

    console.log("unmount");
  },


  methods: {
    async loadPoster() {
      this.loading = true;
      let poster = (await PosterService.getPoster(this.poster.id)).data;
      if (!poster.options) poster.options = [];
      this.poster = poster;


      //load font
      this.fonts = (await PosterService.getFonts()).data;

      //load fontsets
      this.fontSets = (await FontService.getFontSets()).data;

      let source = null;
      try {
        source = decodeURIComponent(poster.svg)
      } catch (err) {
        source = poster.svg;
      }
      this.setSVGFile(source);
      this.loading = false;
    },


    async savePoster() {
      this.poster.svg = this.parser.getSVGFile();

      //set placeholders for text fields in options
      this.poster.options.forEach((option) => {
        if (option.type == "text") {
          option.placeholder = $("#" + option.targetId)[0].innerHTML;
        }

        if (option.type == "image") {
          //set aspect ratio of image
          let img = $("#" + option.targetId)[0];
          option.aspectRatio =
            img.width.baseVal.value / img.height.baseVal.value;

          //set width and height of image
          option.width = img.width.baseVal.value;
          option.height = img.height.baseVal.value;
        }

        if (option.type == "color") {
          let selecedColor = $("#" + option.targetId)[0].style.fill;

          //check if rgb format and covert to hex
          if (selecedColor.includes("rgb")) {
            let rgb = selecedColor.replace("rgb(", "").replace(")", "").split(",");
            selecedColor = "#" + ((1 << 24) + (parseInt(rgb[0]) << 16) + (parseInt(rgb[1]) << 8) + parseInt(rgb[2])).toString(16).slice(1);
          }
          option.placeholder = selecedColor;
        }
      });

      let response = (
        await PosterService.updatePoster(this.poster._id, this.poster)
      ).data;
      this.snackbar = true;
      if (response.acknowledged) {
        this.snackbarMessage = "Poster gespeichert";
      } else {
        this.snackbarMessage = "Fehler beim Speichern";
      }
    },

    processUpload(data) {
      let svgFile = data[0];
      let read = new FileReader();
      read.readAsBinaryString(svgFile);
      read.onloadend = () => {
        this.svgFile = read.result;
        this.setSVGFile(read.result);
      };
    },

    setSVGFile(svgData) {
      if (!this.parser) {
        this.parser = new SVGParser(svgData);
        this.svgParsed = true;
      } else {
        this.parser.updateSVG(svgData);
      }
      
      this.svgFile = svgData;
      
      console.log("Parser initialisiert mit Elementen:", {
        textElements: this.parser.getTextElements(),
        imageElements: this.parser.getImageElements()
      });

      $("#editedSVG").innerHTML = this.parser.getSVGFile();
      $("#editedSVG")[0].innerHTML = this.parser.getSVGFile();

      // Verzögertes Update für DOM-Aktualisierung
      this.$nextTick(() => {
        this.updateSVG();
      });
    },


    editId(id) {
      //open modal dialog with id input
      this.editIdModal = true;
      this.oldId = id;
      this.editedId = id;
    },

    saveEditedId() {
      this.parser.renameNodeId(this.oldId, this.editedId);

      //update $("#editedSVG") ids
      $("#" + this.oldId)[0].id = this.editedId;

      this.updateSVG();
      this.editIdModal = false;
      this.oldId = null;
      this.editedId = null;
    },

    updateSVG() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 500);

      if (this.parser) {
        // Aktualisiere den Parser mit dem aktuellen SVG
        this.parser.updateSVG($("#editedSVG")[0].innerHTML);
        
        // Hole die aktualisierten Elemente
        this.elements = this.parser.getElementIds();
        const textElements = this.parser.getTextElements();
        const imageElements = this.parser.getImageElements();

        console.log("Aktualisiere Artboard mit:", {
          textElements,
          imageElements
        });

        // Aktualisiere das Artboard
        if (this.$refs.artboard) {
          this.$refs.artboard.updateSVG(
            this.parser.getSVGFile(),
            textElements,
            imageElements
          );
        }
      }
    },

    updateSvgProps() {
      if (this.selectedTextItem != null) {
        this.selectedTextItemElement =
          this.parser.getTextElements()[this.selectedTextItem];
        this.$refs.svgProps.updateElement(this.selectedTextItemElement);
        this.$refs.svgProps.updateFonts(this.fonts);

      } else if (this.selectedImageItem !== null && this.selectedImageItem !== undefined) {
        const imageElements = Array.from(this.parser.getImageElements());

        console.log("Filtered image elements:", imageElements);

        const selectedElement = imageElements[this.selectedImageItem - 1];
        console.log("Selected element:", selectedElement);

        if (selectedElement && this.$refs.svgProps) {
          // Wichtig: Kopie erstellen um Vue Reactivity zu vermeiden
          const elementCopy = JSON.parse(JSON.stringify({
            ...selectedElement,
            attributes: { ...selectedElement.attributes }
          }));

          this.$refs.svgProps.updateElement(elementCopy);
        }
      } else if (this.selectedElementItem != null) {
        this.selectedElementItemElement =
          this.parser.getGenericElements()[this.selectedElementItem];
        this.$refs.svgProps.updateElement(this.selectedElementItemElement);
      }
    },

    darkModeChanged() {
      this.$refs.artboard.changeDarkmode(this.darkMode);
    },

    //download SVG
    downloadSVG() {
      let svg = this.parser.getSVGFile();
      let blob = new Blob([svg], { type: "image/svg+xml" });
      let url = URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = "image.svg";
      a.click();
      URL.revokeObjectURL(url);
    },

    downloadSVGWithoutImageData() {
      let svg = this.parser.getSVGFile();

      //remove image data between  xlink:href="data:image/png; ... "
      svg = svg.replace(/xlink:href="data:image\/png;base64,.*?"/g, "");


      let blob = new Blob([svg], { type: "image/svg+xml" });
      let url = URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = "artwork.svg";
      a.click();
      URL.revokeObjectURL(url);
    },

    processOptionSelect() {
      let options = this.poster.options.filter(x => x.type == "optionSelect")

      for (let option of options) {
        //target Id of option
        let targetIds = option.targetId;

        $("#" + targetIds[0])[0].style.display = "block";

        for (var i = 1; i < targetIds.length; i++) {
          let targetId = targetIds[i];

          //hide all elements by id
          $("#" + targetId)[0].style.display = "none";
        }
      }

      this.updateSVG();
    },

    async fixImage(s3Url) {
      await PosterService.fixImage(s3Url);
    },
    addNewImageElement() {
      console.log("Starting addNewImageElement");

      // Neues Bild über Parser erstellen
      const newImageElement = this.parser.addImageElement();
      console.log("Created new image element:", newImageElement);

      // SVG DOM aktualisieren
      const svg = $("#editedSVG svg")[0];
      if (svg) {
        const imageElement = document.createElementNS("http://www.w3.org/2000/svg", "image");
        Object.entries(newImageElement.attributes).forEach(([key, value]) => {
          imageElement.setAttribute(key, value);
        });

        svg.appendChild(imageElement);

        // Parser aktualisieren
        this.parser.updateSVG($("#editedSVG")[0].innerHTML);

        // Sicherstellen, dass das Element in der Liste ist
        const imageElements = this.parser.getImageElements();
        console.log("Image elements after update:", imageElements);

        // Index des neuen Elements finden
        const newIndex = imageElements.findIndex(
          el => el.attributes && el.attributes.id === newImageElement.attributes.id
        );
        console.log("New element index:", newIndex);

        if (newIndex !== -1) {
          // Wichtig: Vue Reactivity beachten
          this.$set(this, 'selectedImageItem', newIndex);

          // UI aktualisieren
          this.$nextTick(() => {
            this.updateSvgProps();
          });
        }
      }
    },

    async handleShapeUpload(file) {
      if (!file) return;

      this.shapeUploading = true;
      try {
        // Lese die SVG-Datei
        const reader = new FileReader();
        reader.onload = async (e) => {
          const shapeData = e.target.result;
          
          // Upload zum Server

          //TODO: posterId aus data holen
          let posterId = this.poster._id;
          await PosterService.uploadShape(posterId, shapeData);
          
          this.currentShape = shapeData;
          this.$toast.success('Shape wurde erfolgreich hochgeladen');
        };
        reader.readAsText(file);
      } catch (error) {
        console.error('Fehler beim Upload des Shapes:', error);
        this.$toast.error('Fehler beim Upload des Shapes');
      } finally {
        this.shapeUploading = false;
      }
    },
  },

  computed: {
    previewSVG() {
      if (this.parser && this.parser.getSVGFile()) {
        console.log("Preview SVG asked");
        return this.parser.getSVGFile();
      } else return null;
    },
  },
};
</script>


<style scoped>
.expanded {
  width: 70%;
}

*::-webkit-scrollbar {
  display: none;
}
</style>
