<template>
  <canvas id="canvas"></canvas>
</template>

<script>
import EventBus from "@/event-bus";

export default {
  name: "SvgArtboard",


  data() {
    return {
      svg: null,
      unload: false,
      textElements: null,
      imageElements: null,
      render: false,
      darkMode: false,
    };
  },

  mounted() {
    this.run();
  },

  watch: {
    svg: {
      handler(newSvg) {
        if (newSvg) {
          console.log("SVG-Daten aktualisiert, starte Rendering");
          this.requestRender();
        }
      },
      immediate: true
    }
  },

  beforeDestroy() {
    console.log("unmounted artboard");
    this.unload = true;
  },


  methods: {

    updateSVG(svg, textElements = null, imageElements = null) {  // Parameter für Bildelemente hinzugefügt
      console.log("updateSVG called with:", {
        svgLength: svg?.length,
        hasTextElements: textElements !== null,
        hasImageElements: imageElements !== null,
        textElements,
        imageElements
      });
      this.svg = svg;
      this.textElements = textElements;
      this.imageElements = imageElements;
      this.requestRender();
    },

    changeDarkmode(isDarkmode) {
      this.darkMode = isDarkmode;
      this.render = true;
    },


    run() {
      EventBus.$on("SVG_EDITED", () => {
        console.log("SVG_EDITED event received");
        this.requestRender();
      });

      window.onbeforeunload = () => {
        this.unload = true;
        console.log("unmounted artboard");
      };

      window.onresize = () => {
        this.requestRender();
      };

      let canvas = document.getElementById("canvas");
      let ctx = canvas.getContext("2d");
      let dimensions = null;
      let rafId = null;
      let isRendering = false;
      let cachedImage = null;

      // Kamera-Einstellungen
      let cameraOffset = { x: 0, y: 0 };
      let cameraZoom = 1.0;
      const MAX_ZOOM = 3;
      const MIN_ZOOM = 0.1;
      const SCROLL_SENSITIVITY = 0.0015;
      let self = this;

      // Optimierte requestRender Funktion
      this.requestRender = () => {
        if (!isRendering) {
          isRendering = true;
          rafId = requestAnimationFrame(draw);
        }
      };

      // Lade das SVG nur wenn es sich ändert
      function loadSVG() {
        return new Promise((resolve, reject) => {
          if (!self.svg) {
            reject(new Error("Kein SVG vorhanden"));
            return;
          }

          const image = new Image();
          image.onload = () => {
            cachedImage = image;
            resolve(image);
          };
          image.onerror = () => {
            reject(new Error("Fehler beim Laden des SVG"));
          };
          const svgBase64 = btoa(unescape(encodeURIComponent(self.svg)));
          image.src = "data:image/svg+xml;base64," + svgBase64;
        });
      }

      // Watch für SVG-Änderungen
      let isInitialLoad = true;
      this.$watch('svg', async (newSvg) => {
        if (newSvg) {
          try {
            // Dimensionen direkt aus den Attributen extrahieren
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = newSvg;
            const svgElement = tempDiv.querySelector('svg');
            
            if (svgElement) {
              const viewBox = svgElement.getAttribute('viewBox');
              if (viewBox) {
                const [, , width, height] = viewBox.split(' ').map(Number);
                dimensions = { width, height };
              } else {
                dimensions = {
                  width: parseFloat(svgElement.getAttribute('width')) || 800,
                  height: parseFloat(svgElement.getAttribute('height')) || 600
                };
              }
            } else {
              dimensions = { width: 800, height: 600 };
            }

            await loadSVG();
            
            // Nur beim ersten Laden initialen Zoom und Position setzen
            if (isInitialLoad) {
              const canvasRect = canvas.getBoundingClientRect();
              const padding = 40;
              const scaleX = (canvasRect.width - padding * 2) / dimensions.width;
              const scaleY = (canvasRect.height - padding * 2) / dimensions.height;
              
              cameraZoom = Math.min(scaleX, scaleY);
              cameraZoom = Math.min(Math.max(cameraZoom, MIN_ZOOM), MAX_ZOOM);
              
              cameraOffset.x = 0;
              cameraOffset.y = 0;
              
              isInitialLoad = false;
            }
            
            this.requestRender();
          } catch (error) {
            console.error("Fehler beim Laden des neuen SVG:", error);
          }
        }
      });

      function limitPan() {
        const canvas = document.getElementById("canvas");
        const viewportWidth = canvas.width;
        const viewportHeight = canvas.height;

        // Berechne die maximalen Grenzen basierend auf Zoom und Canvas-Größe
        const maxOffsetX = Math.max(viewportWidth, dimensions ? dimensions.width * cameraZoom : 0) * 0.5;
        const maxOffsetY = Math.max(viewportHeight, dimensions ? dimensions.height * cameraZoom : 0) * 0.5;

        // Begrenzen der Offset-Werte
        cameraOffset.x = Math.min(Math.max(cameraOffset.x, -maxOffsetX), maxOffsetX);
        cameraOffset.y = Math.min(Math.max(cameraOffset.y, -maxOffsetY), maxOffsetY);
      }

      function adjustZoom(delta, mouseX, mouseY) {
        const oldZoom = cameraZoom;
        const newZoom = Math.min(Math.max(cameraZoom * (1 - delta), MIN_ZOOM), MAX_ZOOM);

        if (oldZoom !== newZoom) {
          // Berechne die Position des Mauszeigers relativ zum Canvas
          const canvasRect = canvas.getBoundingClientRect();
          
          // Konvertiere Mausposition zu Canvas-Koordinaten
          const mouseXCanvas = mouseX - canvasRect.left;
          const mouseYCanvas = mouseY - canvasRect.top;

          // Berechne die Weltposition des Mauszeigers vor dem Zoom
          const worldX = (mouseXCanvas - canvas.width/2 - cameraOffset.x) / oldZoom + canvas.width/2;
          const worldY = (mouseYCanvas - canvas.height/2 - cameraOffset.y) / oldZoom + canvas.height/2;

          // Aktualisiere den Zoom
          cameraZoom = newZoom;

          // Berechne die neue Position des Offsets, sodass der Mauspunkt an der gleichen Stelle bleibt
          cameraOffset.x = mouseXCanvas - ((worldX - canvas.width/2) * newZoom + canvas.width/2);
          cameraOffset.y = mouseYCanvas - ((worldY - canvas.height/2) * newZoom + canvas.height/2);

          limitPan();
          self.requestRender();
        }
      }

      let isDragging = false;
      let lastMousePos = { x: 0, y: 0 };

      function onPointerDown(e) {
        isDragging = true;
        const pos = getEventLocation(e);
        lastMousePos = { ...pos };
      }

      function onPointerUp() {
        isDragging = false;
        initialPinchDistance = null;
      }

      function onPointerMove(e) {
        if (isDragging) {
          const pos = getEventLocation(e);
          const dx = pos.x - lastMousePos.x;
          const dy = pos.y - lastMousePos.y;
          
          cameraOffset.x += dx;
          cameraOffset.y += dy;
          
          limitPan();
          lastMousePos = { ...pos };
          self.requestRender();
        }
      }

      function getEventLocation(e) {
        if (e.touches && e.touches.length === 1) {
          return { x: e.touches[0].clientX, y: e.touches[0].clientY };
        } else if (e.clientX && e.clientY) {
          return { x: e.clientX, y: e.clientY };
        }
        return null;
      }

      // Event Listeners
      canvas.addEventListener("wheel", (e) => {
        e.preventDefault();
        const delta = e.deltaY * SCROLL_SENSITIVITY;
        adjustZoom(delta, e.clientX, e.clientY);
      }, { passive: false });

      canvas.addEventListener("mousedown", onPointerDown);
      canvas.addEventListener("mouseup", onPointerUp);
      canvas.addEventListener("mousemove", onPointerMove);
      canvas.addEventListener("mouseleave", onPointerUp);

      // Touch Event Listener mit verbessertem Pinch-Zoom
      let initialPinchDistance = null;
      let lastPinchCenter = null;

      function handlePinch(e) {
        e.preventDefault();

        const touch1 = { x: e.touches[0].clientX, y: e.touches[0].clientY };
        const touch2 = { x: e.touches[1].clientX, y: e.touches[1].clientY };

        const currentCenter = {
          x: (touch1.x + touch2.x) / 2,
          y: (touch1.y + touch2.y) / 2
        };

        const currentDistance = Math.hypot(
          touch1.x - touch2.x,
          touch1.y - touch2.y
        );

        if (initialPinchDistance === null) {
          initialPinchDistance = currentDistance;
          lastPinchCenter = currentCenter;
          return;
        }

        // Zoom-Berechnung
        const zoomDelta = (currentDistance - initialPinchDistance) / initialPinchDistance;
        adjustZoom(-zoomDelta * 0.5, currentCenter.x, currentCenter.y);

        // Pan während des Pinch
        if (lastPinchCenter) {
          cameraOffset.x += currentCenter.x - lastPinchCenter.x;
          cameraOffset.y += currentCenter.y - lastPinchCenter.y;
          limitPan();
        }

        lastPinchCenter = currentCenter;
        initialPinchDistance = currentDistance;
      }

      function handleTouch(e, singleTouchHandler) {
        e.preventDefault();
        if (e.touches.length === 1) {
          singleTouchHandler(e);
        } else if (e.touches.length === 2) {
          handlePinch(e);
        }
      }

      canvas.addEventListener("touchstart", (e) => handleTouch(e, onPointerDown));
      canvas.addEventListener("touchend", (e) => handleTouch(e, onPointerUp));
      canvas.addEventListener("touchmove", (e) => handleTouch(e, onPointerMove));

      function drawCheckerboard(ctx, width, height) {
        // Berechne die Tile-Größe basierend auf der Canvas-Größe
        // Wir nehmen die kleinere Dimension als Basis
        const minDimension = Math.min(width, height);
        const tileSize = Math.max(minDimension / 50, 8); // Mindestens 8px, maximal 1/50 der kleineren Dimension
        
        const lightColor = "#ffffff";
        const darkColor = "#e0e0e0";

        // Speichere den aktuellen Clip-Bereich
        ctx.save();
        
        // Erstelle einen Clip-Pfad in der Größe des SVGs
        ctx.beginPath();
        ctx.rect(0, 0, width, height);
        ctx.clip();

        // Berechne die Anzahl der benötigten Kacheln
        const tilesX = Math.ceil(width / tileSize);
        const tilesY = Math.ceil(height / tileSize);

        // Zeichne das Schachbrettmuster
        for (let y = 0; y < tilesY; y++) {
          for (let x = 0; x < tilesX; x++) {
            const isEvenRow = y % 2 === 0;
            const isEvenCol = x % 2 === 0;
            ctx.fillStyle = isEvenRow === isEvenCol ? lightColor : darkColor;
            ctx.fillRect(
              x * tileSize,
              y * tileSize,
              tileSize + 1, // +1 um Lücken zu vermeiden
              tileSize + 1
            );
          }
        }

        // Füge den halbtransparenten grauen Layer hinzu
        ctx.fillStyle = "rgba(128, 128, 128, 0.6)"; // 30% Grau
        ctx.fillRect(0, 0, width, height);

        // Stelle den ursprünglichen Clip-Bereich wieder her
        ctx.restore();
      }

      function draw() {
        if (self.unload) {
          isRendering = false;
          return;
        }

        try {
          if (!cachedImage || !dimensions) {
            isRendering = false;
            return;
          }

          // Canvas-Größenanpassung
          const dpr = window.devicePixelRatio || 1;
          const displayWidth = window.innerWidth;
          const displayHeight = window.innerHeight;
          
          if (canvas.width !== displayWidth * dpr || canvas.height !== displayHeight * dpr) {
            canvas.width = displayWidth * dpr;
            canvas.height = displayHeight * dpr;
            canvas.style.width = `${displayWidth}px`;
            canvas.style.height = `${displayHeight}px`;
            ctx.scale(dpr, dpr);
          }

          // Clear und Transform
          ctx.setTransform(1, 0, 0, 1, 0, 0);
          ctx.clearRect(0, 0, canvas.width, canvas.height);

          // Zentriere und zoome
          ctx.translate(canvas.width / 2, canvas.height / 2);
          ctx.scale(cameraZoom, cameraZoom);
          ctx.translate(
            -canvas.width / 2 + cameraOffset.x / cameraZoom,
            -canvas.height / 2 + cameraOffset.y / cameraZoom
          );

          // Zeichne das Schachbrettmuster nur im SVG-Bereich
          drawCheckerboard(ctx, dimensions.width, dimensions.height);

          // Zeichne das zwischengespeicherte SVG
          ctx.fillStyle = self.darkMode ? "#000000" : "transparent";
          ctx.fillRect(0, 0, dimensions.width, dimensions.height);
          ctx.drawImage(cachedImage, 0, 0, dimensions.width, dimensions.height);
          

          if (self.textElements) {
            drawTextElements(ctx, self.textElements, dimensions);
          }

          drawMeasurementLines(ctx, dimensions);
          
          isRendering = false;
          
        } catch (error) {
          console.error("Fehler beim Zeichnen:", error);
          isRendering = false;
        }
      }

      function drawMeasurementLines(ctx, dimensions) {
        const lineWidth = dimensions.height / 100;
        
        // Draw measurement lines
        ctx.strokeStyle = "magenta";
        ctx.lineWidth = lineWidth;
        
        // Vertical line
        ctx.beginPath();
        ctx.moveTo(-lineWidth, 0);
        ctx.lineTo(-lineWidth, dimensions.height);
        ctx.stroke();
        
        // Horizontal line
        ctx.beginPath();
        ctx.moveTo(0, -lineWidth);
        ctx.lineTo(dimensions.width, -lineWidth);
        ctx.stroke();

        // Draw dimensions text
        ctx.fillStyle = "magenta";
        ctx.font = `${dimensions.width / 20}px Arial`;
        
        // Width text
        ctx.fillText(
          Math.round(dimensions.width),
          dimensions.width / 2 + 5,
          -(lineWidth * 2)
        );
        
        // Height text
        ctx.save();
        ctx.rotate(Math.PI / 2);
        ctx.fillText(
          Math.round(dimensions.height),
          dimensions.height / 2 + 5,
          lineWidth * 8
        );
        ctx.restore();
      }

     

      function drawTextElements(ctx, elements) {
        elements.forEach(element => {
          if (element.attributes.x && element.attributes.y) {
            const x = parseFloat(element.attributes.x.replace("px", ""));
            const y = parseFloat(element.attributes.y.replace("px", ""));

      
            // Draw boxes if dimensions are set
            if (element.attributes.maxwidth && element.attributes.maxheight) {
              drawTextBox(ctx, x, y, element, "magenta", "max");
            }

          }
        });
      }

      function drawTextBox(ctx, x, y, element, color, type) {
        ctx.strokeStyle = color;
        ctx.lineWidth = 2;
        ctx.beginPath();
        
        const width = parseInt(element.attributes[`${type}width`]);
        const height = parseInt(element.attributes[`${type}height`]);
        const alignment = element.attributes["text-anchor"] || "center";
        const boxX = alignment === "middle" ? x - width / 2 : alignment === "start" ? x : x - width;
        const boxY = alignment === "middle" ? y - height / 2 : alignment === "start" ? y : y - height;
        
        ctx.rect(boxX, boxY, width, height);
        ctx.stroke();
      }

      // Cleanup bei Komponenten-Zerstörung
      this.$once('hook:beforeDestroy', () => {
        if (rafId) {
          cancelAnimationFrame(rafId);
        }
      });

      // Initial render
      if (this.svg) {
        console.log("Starte initiales Rendering");
        this.requestRender();
      } else {
        console.log("Warte auf SVG-Daten für initiales Rendering");
      }
    },
  },
};
</script>

<style scoped>
#canvas {
  width: 100%;
  height: 100%;
  background: url('../assets/gridbg.png') repeat;
}
</style>