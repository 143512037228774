<template>
    <v-container fluid>
        <!-- Header Section -->
        <v-row>
            <v-col cols="12">
                <v-card elevation="2" class="mb-6">
                    <v-card-title class="py-2">
                        <v-icon large color="primary" class="mr-3">mdi-package-variant</v-icon>
                        <span class="text-h5">Bestellung bearbeiten</span>
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="orderId"
                            label="Bestellnummer"
                            hide-details
                            class="max-width-300 mr-4"
                            dense
                            outlined
                            append-icon="mdi-magnify"
                            @click:append="fetchOrderDetails"
                            @keyup.enter="fetchOrderDetails">
                        </v-text-field>
                        <v-btn 
                            color="primary"
                            @click="fetchOrderDetails"
                            :loading="loading"
                            elevation="2">
                            <v-icon left>mdi-refresh</v-icon>
                            Bestellung laden
                        </v-btn>
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>

        <!-- Error Alert -->
        <v-alert
            v-if="error"
            type="error"
            dismissible
            prominent
            border="left"
            class="mb-4">
            <v-row align="center">
                <v-col class="grow">{{ error }}</v-col>
                <v-col class="shrink">
                    <v-btn color="white" text @click="error = null">
                        Schließen
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>

        <template v-if="orderDetails">
            <!-- Order Information Section -->
            <v-row>
                <v-col cols="12" md="6">
                    <v-card class="mb-6" elevation="2">
                        <v-card-title class="py-2 primary--text">
                            <v-icon color="primary" class="mr-2">mdi-information</v-icon>
                            Bestelldetails
                            <v-chip 
                                class="ml-4" 
                                :color="getStateColor(orderDetails.state)"
                                small
                                label>
                                {{ orderDetails.state }}
                            </v-chip>
                        </v-card-title>
                        <v-card-text class="pt-4">
                            <v-row dense>
                                <v-col cols="6">
                                    <div class="caption grey--text text--darken-1">Bestellnummer</div>
                                    <div class="body-1">{{ orderDetails.orderId }}</div>
                                </v-col>
                                <v-col cols="6">
                                    <div class="caption grey--text text--darken-1">Shop</div>
                                    <div class="body-1">{{ orderDetails.shop }}</div>
                                </v-col>
                                <v-col cols="6" class="mt-3">
                                    <div class="caption grey--text text--darken-1">Erstelldatum</div>
                                    <div class="body-1">{{ formatDate(orderDetails.createDate) }}</div>
                                </v-col>
                                <v-col cols="6" class="mt-3">
                                    <div class="caption grey--text text--darken-1">Kunde</div>
                                    <div class="body-1">{{ orderDetails.customer.firstName }} {{ orderDetails.customer.lastName }}</div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" md="6">
                    <v-card class="mb-6" elevation="2">
                        <v-card-title class="py-2 primary--text">
                            <v-icon color="primary" class="mr-2">mdi-truck-delivery</v-icon>
                            Versandinformationen
                        </v-card-title>
                        <v-card-text class="pt-4">
                            <v-row dense>
                                <v-col cols="6">
                                    <div class="caption grey--text text--darken-1">Versanddatum</div>
                                    <div class="body-1">{{ formatDate(orderDetails.shipping.shippmentDate) || 'Nicht festgelegt' }}</div>
                                </v-col>
                                <v-col cols="6">
                                    <div class="caption grey--text text--darken-1">Letztes Versanddatum</div>
                                    <div class="body-1">{{ formatDate(orderDetails.shipping.lastShippmentDate) || 'Nicht festgelegt' }}</div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <!-- Import Card -->
            <v-row v-if="orderDetails.products.filter(x => x.attributes && x.attributes.designId).length == 0">
                <v-col cols="12">
                    <v-card elevation="2">
                        <v-card-title class="warning py-2">
                            <v-icon left color="warning darken-2">mdi-alert</v-icon>
                            <span class="warning--text text--darken-2">Bestellung muss importiert werden</span>
                        </v-card-title>
                        <v-card-text class="pt-4">
                            <p class="body-1">Bitte stellen Sie sicher, dass die SKU bereits mit einem Design verknüpft wurde.</p>
                        </v-card-text>
                        <v-card-actions class="pb-4 px-4">
                            <v-btn 
                                color="warning"
                                @click="importOrder"
                                :loading="loading"
                                elevation="2">
                                <v-icon left>mdi-import</v-icon>
                                Bestellung importieren
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>

            <!-- Products Section -->
            <div v-for="product in orderDetails.products.filter(x => x.attributes && x.attributes.designId)"
                 :key="product.attributes.designId">
                <v-card class="mb-6" elevation="2">
                    <v-card-title class="py-2 primary--text">
                        <v-icon color="primary" class="mr-2">mdi-cube-outline</v-icon>
                        Produkt: {{ product.name }}
                        <v-chip class="ml-4" small outlined>SKU: {{ product.sku }}</v-chip>
                    </v-card-title>

                    <v-card-text class="pt-4">
                        <v-row>
                            <!-- Preview Image -->
                            <v-col cols="12" md="6" v-if="product.design">
                                <v-card flat class="preview-card">
                                    <div class="preview-wrapper preview-wrapper-large">
                                        <v-img 
                                            :ref="product.design.options.designId"
                                            :src="product.design.options.poster"
                                            contain
                                            position="center"
                                            max-height="500"
                                            class="preview-image">
                                            <template v-slot:placeholder>
                                                <v-row align="center" justify="center" class="fill-height">
                                                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                                </v-row>
                                            </template>
                                        </v-img>
                                    </div>

                                    <v-card-actions class="preview-actions pa-2">
                                        <v-btn
                                            small
                                            color="success"
                                            @click="saveChanges(product.design.options)"
                                            :loading="loading"
                                            class="mr-2"
                                            elevation="2">
                                            <v-icon left small>mdi-content-save</v-icon>
                                            Speichern
                                        </v-btn>

                                        <v-btn
                                            small
                                            color="primary"
                                            @click="designPreviewUrl(product.attributes.designId)"
                                            elevation="2">
                                            <v-icon left small>mdi-refresh</v-icon>
                                            Neu generieren
                                        </v-btn>

                                        <v-spacer></v-spacer>

                                        <v-btn
                                            small
                                            color="info"
                                            @click="analyzeTransparency(product)"
                                            :loading="product.analyzingTransparency"
                                            elevation="2">
                                            <v-icon left small>mdi-image-search</v-icon>
                                            Transparenzen
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>

                                <!-- Transparenzanalyse direkt unter dem Vorschaubild -->
                                <v-expand-transition>
                                    <v-card v-if="product.design?.options?.transparencyData" 
                                           flat 
                                           class="mt-4 transparency-results">
                                        <v-card-title class="py-2 subtitle-2">
                                            <v-icon small color="primary" class="mr-2">mdi-image-filter-center-focus</v-icon>
                                            Transparenzanalyse
                                            <v-chip
                                                class="ml-2"
                                                x-small
                                                :color="getTransparencyColor(product.design.options.transparencyData)"
                                                label>
                                                {{ getTransparencyPercentage(product.design.options.transparencyData) }}%
                                            </v-chip>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                small
                                                color="success"
                                                @click="applyAllCrops(product)"
                                                :loading="product.applyingAllCrops"
                                                :disabled="!hasAnyCroppedImages(product)">
                                                <v-icon small left>mdi-check-all</v-icon>
                                                Alle übernehmen
                                            </v-btn>
                                        </v-card-title>
                                        
                                        <v-card-text class="pa-2">
                                            <v-row dense>
                                                <v-col
                                                    v-for="(data, field) in product.design.options.transparencyData"
                                                    :key="field"
                                                    cols="12">
                                                    <v-card outlined class="transparency-card">
                                                        <div class="d-flex align-center pa-2">
                                                            <!-- Original Bild mit Transparenz-Overlay -->
                                                            <div class="transparency-preview" @click="openTransparencyPreview(data.url, field, data)">
                                                                <canvas :ref="'transparencyCanvas-' + field" 
                                                                        class="transparency-canvas">
                                                                </canvas>
                                                                <img :src="data.url"
                                                                     :alt="field"
                                                                     @load="initTransparencyVisualization(field, data)"
                                                                     style="opacity: 0; position: absolute;">
                                                            </div>
                                                            
                                                            <!-- Pfeil -->
                                                            <v-icon class="mx-4" color="primary">mdi-arrow-right</v-icon>
                                                            
                                                            <!-- Zugeschnittenes Bild -->
                                                            <div v-if="data.url_cropped" 
                                                                 class="transparency-preview" 
                                                                 @click="openImagePreviewDialog(data.url_cropped)">
                                                                <v-img
                                                                    :src="data.url_cropped + '?v=' + timestamp"
                                                                    :alt="'Zugeschnittenes ' + field"
                                                                    class="transparency-canvas"
                                                                    contain>
                                                                </v-img>
                                                            </div>
                                                            <div v-else class="transparency-preview transparency-preview-empty">
                                                                <div class="text-center caption grey--text">
                                                                    Noch kein<br>zugeschnittenes Bild
                                                                </div>
                                                            </div>

                                                            <div class="transparency-info ml-4 flex-grow-1">
                                                                <div class="subtitle-2">{{ field }}</div>
                                                                <div class="caption grey--text">
                                                                    {{ data.width }}x{{ data.height }}px
                                                                </div>
                                                                <div class="caption">
                                                                    <v-chip
                                                                        x-small
                                                                        :color="getTransparencyColor({ transparencyPercentage: data.transparencyPercentage })"
                                                                        label>
                                                                        {{ data.transparencyPercentage.toFixed(4) }}% Transparenz
                                                                    </v-chip>
                                                                </div>
                                                            </div>

                                                            <div class="transparency-actions">
                                                                <template v-if="data.url_cropped">
                                                                    <v-btn
                                                                        x-small
                                                                        color="success"
                                                                        @click="applyTransparencyCrop(product, field, data.url_cropped)"
                                                                        :loading="product.applyingCrop"
                                                                        :disabled="product.applyingCrop">
                                                                        <v-icon x-small left>mdi-check</v-icon>
                                                                        Übernehmen
                                                                    </v-btn>
                                                                </template>
                                                            </div>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-expand-transition>
                            </v-col>

                            <!-- Design Options -->
                            <v-col cols="12" md="6" v-if="product.design">
                                <v-card flat>
                                    <v-card-text class="pa-0">
                                        <v-row dense>
                                            <v-col cols="12">
                                                <div class="d-flex align-center mb-4">
                                                    <v-text-field
                                                        label="Design ID"
                                                        v-model="product.design.designId"
                                                        readonly
                                                        dense
                                                        outlined
                                                        hide-details
                                                        class="flex-grow-1">
                                                    </v-text-field>

                                                    <v-btn-group class="ml-4">
                                                        <v-btn
                                                            v-if="product.design.options.pdf"
                                                            small
                                                            text
                                                            color="primary"
                                                            :href="product.design.options.pdf">
                                                            <v-icon small left>mdi-file-pdf</v-icon>
                                                            PDF
                                                        </v-btn>
                                                        <v-btn
                                                            v-if="product.design.options.poster"
                                                            small
                                                            text
                                                            color="primary"
                                                            :href="product.design.options.poster">
                                                            <v-icon small left>mdi-file-image</v-icon>
                                                            PNG
                                                        </v-btn>
                                                    </v-btn-group>

                                                    <v-btn
                                                        small
                                                        color="primary"
                                                        @click="openPdfUploadDialog(product)"
                                                        class="ml-2"
                                                        elevation="2">
                                                        <v-icon left small>mdi-file-upload</v-icon>
                                                        PDF
                                                    </v-btn>
                                                </div>
                                            </v-col>

                                            <template v-if="product.posterTemplate">
                                                <v-col cols="12" v-for="option in product.posterTemplate.options" :key="option.field">
                                                    <template v-if="showAllFields || product.design.options[option.field]">
                                                        <v-text-field
                                                            v-if="option.type === 'text'"
                                                            :label="option.label"
                                                            v-model="product.design.options[option.field]"
                                                            :placeholder="option.placeholder"
                                                            dense
                                                            outlined
                                                            counter>
                                                        </v-text-field>

                                                        <v-card
                                                            v-else-if="option.type === 'image'"
                                                            flat
                                                            class="pa-2 mb-2 image-option-card">
                                                            <div class="d-flex flex-column">
                                                                <div class="text-subtitle-2 mb-2">{{ option.label }}</div>

                                                                <!-- Bildvorschau -->
                                                                <div v-if="product.design.options[option.field]"
                                                                     class="image-preview-container mb-2">
                                                                    <div class="preview-wrapper"
                                                                         :style="{
                                                                             height: '300px',
                                                                             width: '100%',
                                                                             margin: '0 auto',
                                                                             position: 'relative'
                                                                         }">
                                                                        <canvas
                                                                            :id="getCanvasId(product.design.designId, option.field)"
                                                                            class="image-canvas"
                                                                            @click="openImagePreview(product.design.options[option.field], product, option.field, option.aspectRatio)">
                                                                        </canvas>
                                                                        <img
                                                                            :id="getImageId(product.design.designId, option.field)"
                                                                            :src="product.design.options[option.field]"
                                                                            style="display: none;"
                                                                            @load="initCanvas(product.design.designId, option.field)">
                                                                    </div>
                                                                    <div class="image-dimensions caption grey--text text-center mt-1">
                                                                        {{ getImageDimensions(option) }}
                                                                    </div>
                                                                </div>

                                                                <!-- Upload Controls -->
                                                                <div class="d-flex align-center">
                                                                    <v-menu
                                                                        offset-y
                                                                        :close-on-content-click="false"
                                                                    >
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-btn
                                                                                color="primary"
                                                                                v-bind="attrs"
                                                                                v-on="on"
                                                                                class="flex-grow-1"
                                                                            >
                                                                                <v-icon left>mdi-image-multiple</v-icon>
                                                                                Originalbild auswählen
                                                                            </v-btn>
                                                                        </template>
                                                                        <v-card max-width="400">
                                                                            <v-card-title class="subtitle-1">Originalbilder</v-card-title>
                                                                            <v-divider></v-divider>
                                                                            <v-card-text class="pa-2">
                                                                                <v-row dense>
                                                                                    <v-col cols="6" v-for="(url, key) in getOriginalImages(product)" :key="key">
                                                                                        <v-card
                                                                                            outlined
                                                                                            class="original-image-card"
                                                                                            @click="openOriginalImage(url, product, option.field)"
                                                                                        >
                                                                                            <v-img
                                                                                                :src="getProxiedImageUrl(url)"
                                                                                                aspect-ratio="1"
                                                                                                class="grey lighten-2"
                                                                                            >
                                                                                                <template v-slot:placeholder>
                                                                                                    <v-row
                                                                                                        class="fill-height ma-0"
                                                                                                        align="center"
                                                                                                        justify="center"
                                                                                                    >
                                                                                                        <v-progress-circular
                                                                                                            indeterminate
                                                                                                            color="grey lighten-5"
                                                                                                        ></v-progress-circular>
                                                                                                    </v-row>
                                                                                                </template>
                                                                                            </v-img>
                                                                                        </v-card>
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </v-card-text>
                                                                        </v-card>
                                                                    </v-menu>

                                                                    <v-btn
                                                                        v-if="product.design.options[option.field]"
                                                                        icon
                                                                        small
                                                                        color="error"
                                                                        class="ml-2"
                                                                        @click="removeImage(option.field, product)">
                                                                        <v-icon>mdi-delete</v-icon>
                                                                    </v-btn>
                                                                </div>
                                                            </div>
                                                        </v-card>
                                                    </template>
                                                </v-col>
                                            </template>

                                            <v-col cols="12" class="text-center">
                                                <v-btn
                                                    v-if="!showAllFields && hasHiddenFields"
                                                    color="primary"
                                                    text
                                                    @click="showAllFields = true">
                                                    <v-icon left>mdi-plus</v-icon>
                                                    Weitere Felder anzeigen
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>

                                    <v-divider class="my-4"></v-divider>

                                    <v-card-actions class="pa-0">
                                        <v-btn
                                            color="success"
                                            @click="saveChanges(product.design.options)"
                                            :loading="loading"
                                            class="mr-2"
                                            elevation="2">
                                            <v-icon left>mdi-content-save</v-icon>
                                            Speichern
                                        </v-btn>

                                        <v-spacer></v-spacer>

                                        <v-btn-group>
                                            <v-btn
                                                v-if="product.design.options.pdf"
                                                color="primary"
                                                text
                                                :href="product.design.options.pdf">
                                                <v-icon left>mdi-file-pdf</v-icon>
                                                PDF
                                            </v-btn>

                                            <v-btn
                                                v-if="product.design.options.poster"
                                                color="primary"
                                                text
                                                :href="product.design.options.poster">
                                                <v-icon left>mdi-file-image</v-icon>
                                                PNG
                                            </v-btn>
                                        </v-btn-group>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </div>
        </template>

        <!-- Loading Overlay -->
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <!-- Image Preview/Cropper Dialog -->
        <v-dialog
            v-model="showImagePreviewDialog"
            max-width="90vw"
            persistent>
            <v-card>
                <v-toolbar dark color="primary" dense>
                    <v-btn icon @click="closeImagePreviewDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ previewMode === 'crop' ? 'Bild zuschneiden' : 'Bildvorschau' }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn-toggle v-model="previewMode" mandatory dense>
                            <v-btn value="preview" small>
                                <v-icon small>mdi-eye</v-icon>
                                Vorschau
                            </v-btn>
                            <v-btn value="crop" small>
                                <v-icon small>mdi-crop</v-icon>
                                Zuschneiden
                            </v-btn>
                        </v-btn-toggle>
                        
                        <template v-if="previewMode === 'crop'">
                            <v-btn icon small @click="cropperRotateLeft">
                                <v-icon>mdi-rotate-left</v-icon>
                            </v-btn>
                            <v-btn icon small @click="cropperRotateRight">
                                <v-icon>mdi-rotate-right</v-icon>
                            </v-btn>
                            <v-btn icon small @click="cropperFlipH">
                                <v-icon>mdi-flip-horizontal</v-icon>
                            </v-btn>
                            <v-btn icon small @click="cropperFlipV">
                                <v-icon>mdi-flip-vertical</v-icon>
                            </v-btn>
                            <v-btn icon small @click="cropperReset">
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                            <v-btn text small @click="saveCrop" :loading="saving" :disabled="saving">
                                Übernehmen
                            </v-btn>
                        </template>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text class="pa-0">
                    <div class="preview-container">
                        <!-- Preview Mode -->
                        <template v-if="previewMode === 'preview'">
                            <v-img
                                :src="previewImageUrl"
                                contain
                                max-height="90vh"
                                class="preview-image">
                            </v-img>
                        </template>

                        <!-- Crop Mode -->
                        <vue-cropper
                            v-else
                            ref="cropper"
                            :src="previewImageUrl"
                            :aspect-ratio="currentAspectRatio"
                            :view-mode="1"
                            :drag-mode="'crop'"
                            :auto-crop-area="0.8"
                            :background="true"
                            :movable="true"
                            :rotatable="true"
                            :scalable="true"
                            :zoomable="true"
                            :center="true"
                            :guides="true"
                            :highlight="true"
                            :crop-box-movable="true"
                            :crop-box-resizable="true"
                            :toggle-drag-mode-on-dblclick="false"
                            :min-container-width="1200"
                            :min-container-height="800"
                            @ready="onCropperReady"
                            @error="onCropperError"
                            class="cropper">
                        </vue-cropper>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- PDF Upload Dialog -->
        <v-dialog v-model="showPdfUploadDialog" max-width="500px">
            <v-card>
                <v-card-title>PDF Datei hochladen</v-card-title>
                <v-card-text>
                    <v-file-input
                        v-model="selectedPdfFile"
                        label="PDF Datei auswählen"
                        accept="application/pdf"
                        :rules="[v => !v || v.size < 10000000 || 'PDF Datei muss kleiner als 10MB sein']"
                        show-size
                        outlined>
                    </v-file-input>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="closePdfUploadDialog">
                        Abbrechen
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="uploadPdf"
                        :loading="uploading"
                        :disabled="!selectedPdfFile">
                        Hochladen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<style>
.max-width-300 {
    max-width: 300px;
}

.preview-card {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    overflow: hidden;
    background: repeating-conic-gradient(#808080 0% 25%, #666666 25% 50%) 50% / 20px 20px;
}

.preview-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
}

.preview-wrapper-large {
    height: 500px;
    overflow: hidden;
}

.preview-image {
    border-radius: 4px;
}

.preview-actions {
    background: rgba(255, 255, 255, 0.9) !important;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.transparency-results {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}

.transparency-card {
    transition: transform 0.2s ease-in-out;
}

.transparency-card:hover {
    transform: translateY(-1px);
}

.transparency-preview {
    width: 100px;
    height: 75px;
    position: relative;
    background: repeating-conic-gradient(#808080 0% 25%, #666666 25% 50%) 50% / 10px 10px;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.transparency-preview:hover {
    transform: scale(1.05);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.transparency-preview-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    border: 1px dashed rgba(0, 0, 0, 0.2);
}

.transparency-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.transparency-info {
    min-width: 0;
}

.transparency-actions {
    white-space: nowrap;
}

.transparency-card {
    transition: transform 0.2s ease-in-out;
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.transparency-card:hover {
    transform: translateY(-1px);
}

.image-option-card {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}

.image-preview-container {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    overflow: hidden;
    background: #333;
    padding: 8px;
}

.v-card-title {
    word-break: break-word;
}

.v-btn {
    text-transform: none;
}

/* Cropper Styles */
.preview-container {
    width: 100%;
    height: 90vh;
    background: repeating-conic-gradient(#808080 0% 25%, #666666 25% 50%) 50% / 20px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.preview-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 4px;
}

.cropper {
    height: 800px !important;
    width: 100%;
    background: #000;
}

.cropper-view-box,
.cropper-face {
    border-radius: 0;
}

.cropper-modal {
    background-color: rgba(0, 0, 0, 0.8);
}

.cropper-line {
    background-color: #fff;
}

.cropper-point {
    width: 8px;
    height: 8px;
    background-color: #fff;
    border: 2px solid #7957d5;
}

.cropper-view-box {
    outline: 2px solid #7957d5;
}

.cropper-container {
    min-width: 1200px;
    min-height: 800px;
}

/* Toolbar Anpassungen */
.v-toolbar__content {
    padding: 0 8px;
}

.v-toolbar__items .v-btn {
    margin: 0 2px;
}

.v-btn-toggle {
    margin: 0 8px;
}

.original-image-card {
    cursor: pointer;
    transition: transform 0.2s;
}

.original-image-card:hover {
    transform: scale(1.05);
}
</style>

<script>
import DesignService from '../../services/DesignService'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
    components: {
        VueCropper
    },
    data() {
        return {
            loading: false,
            error: null,
            orderId: '',
            orderDetails: null,
            showSaveButton: false,
            showAllFields: false,
            selectedImage: null,
            imageFiles: {},
            previewMode: 'preview',
            showImagePreview: false,
            currentImage: null,
            currentProduct: null,
            currentField: null,
            currentAspectRatio: 1,
            imageLoaded: false,
            cropperInstance: null,
            saving: false,
            timestamp: Date.now(),
            // Neue Variablen für PDF Upload
            showPdfUploadDialog: false,
            selectedPdfFile: null,
            uploading: false,
            // Neue Variable für Template Dimensionen
            templateDimensions: null,
            showImagePreviewDialog: false,
            previewImageUrl: null,
        }
    },

    computed: {
        formattedOrderDetails() {
            if (!this.orderDetails) {
                return null;
            }

            const formattedProducts = this.orderDetails.products.map((product) => {
                return {
                    name: product.name,
                    sku: product.sku,
                    attributes: product.attributes,
                    qty: product.qty,
                };
            });

            return {
                orderId: this.orderDetails.orderId,
                shop: this.orderDetails.shop,
                createDate: this.formatDate(this.orderDetails.createDate),
                state: this.orderDetails.state,
                customer: {
                    firstName: this.orderDetails.customer.firstName,
                    lastName: this.orderDetails.customer.lastName,
                },
                shipping: {
                    type: this.orderDetails.shipping.type,
                    shipmentDate: this.formatDate(this.orderDetails.shipping.shipmentDate),
                    lastShipmentDate: this.formatDate(this.orderDetails.shipping.lastShipmentDate),
                },
                products: formattedProducts,
            };
        },

        hasHiddenFields() {
            if (!this.orderDetails || !this.showAllFields) {
                return false;
            }

            return this.orderDetails.products.some(product => {
                if (!product.posterTemplate || !product.design) return false;

                return product.posterTemplate.options.some(option => {
                    // Ein Feld ist versteckt, wenn es keinen Wert hat und nicht angezeigt wird
                    return !product.design.options[option.field] && !this.showAllFields;
                });
            });
        }
    },

    created() {
        this.orderId = this.$route.query.orderId
        console.log("Found order in url: ", this.orderId)
        if (this.orderId) {
            this.fetchOrderDetails()
        }
    },

    watch: {
        previewMode(newMode) {
            if (newMode === 'preview') {
                this.$nextTick(() => {
                    this.drawPreviewImage()
                })
            }
        }
    },

    methods: {
        formatDate(dateString) {
            if (!dateString) return '';
            const date = new Date(dateString);
            return date.toLocaleDateString('de-DE');
        },

        getStateColor(state) {
            const stateColors = {
                'UNSHIPPED': 'warning',
                'SHIPPED': 'success',
                'CANCELLED': 'error',
                'PENDING': 'info'
            };
            return stateColors[state] || 'grey';
        },

        async fetchOrderDetails() {
            this.loading = true;
            try {
                const orderDetails = await DesignService.getOrder(this.orderId)
                this.$router.push({ query: { orderId: this.orderId } })

                for (let product of orderDetails.data.products) {
                    if (product.attributes.designId) {
                        product.design = {
                            options: (await DesignService.getDesign(product.attributes.designId)).data,
                            designId: product.attributes.designId,
                            sku: product.sku
                        }

                        // Füge Cache-Busting-Parameter zu allen Bild-URLs hinzu
                        if (product.design.options.poster) {
                            product.design.options.poster = `${product.design.options.poster}?v=${Date.now()}`
                        }

                        // Lade das Poster Template für das Produkt
                        try {
                            const posterTemplate = await DesignService.getPosterTemplate(product.sku)
                            product.posterTemplate = posterTemplate.data
                        } catch (err) {
                            console.error('Fehler beim Laden des Poster Templates:', err)
                        }
                    }
                }

                this.orderDetails = orderDetails.data
            } catch (err) {
                this.error = err.message || 'Fehler beim Laden der Bestellung';
            } finally {
                this.loading = false;
            }
        },

        async saveChanges(design) {
            try {
                this.loading = true;
                const response = await DesignService.saveDesign(design.designId, design);
                const updatedDesign = response.data;

                // Aktualisiere das Design im lokalen State
                for (let product of this.orderDetails.products) {
                    if (product.design && product.design.designId === design.designId) {
                        product.design = {
                            options: updatedDesign.options,
                            designId: updatedDesign.designId,
                            sku: updatedDesign.sku
                        };

                        // Aktualisiere das Vorschaubild
                        if (this.$refs[design.designId] && this.$refs[design.designId][0]) {
                            this.$refs[design.designId][0].src = null;
                            setTimeout(() => {
                                if (this.$refs[design.designId] && this.$refs[design.designId][0]) {
                                    this.$refs[design.designId][0].src = updatedDesign.options.poster + '?' + new Date().getTime();
                                }
                            }, 1500);
                        }
                    }
                }

                this.showSaveButton = false;
                this.$emit('success', 'Änderungen wurden erfolgreich gespeichert');
            } catch (err) {
                this.error = err.message || 'Fehler beim Speichern der Änderungen';
            } finally {
                this.loading = false;
            }
        },

        async designPreviewUrl(product) {
            try {
                //remove query
                product.design.options.poster = product.design.options.poster.split('?')[0]
                product.design.options.poster = product.design.options.poster + '?v=' + this.timestamp
            } catch (err) {
                this.error = err.message || 'Fehler beim Generieren der Vorschau';
                return null;
            }
        },

        async importOrder() {
            try {
                this.loading = true;
                await DesignService.importOrder(this.orderId)
                await this.fetchOrderDetails()
                this.$emit('success', 'Bestellung wurde erfolgreich importiert');
            } catch (err) {
                this.error = err.message || 'Fehler beim Importieren der Bestellung';
            } finally {
                this.loading = false;
            }
        },

        // Neue Methoden für PDF Upload
        openPdfUploadDialog(product) {
            this.currentProduct = product;
            this.selectedPdfFile = null;
            this.showPdfUploadDialog = true;
        },

        closePdfUploadDialog() {
            this.showPdfUploadDialog = false;
            this.selectedPdfFile = null;
            this.currentProduct = null;
        },

        async uploadPdf() {
            if (!this.selectedPdfFile || !this.currentProduct) return;

            try {
                this.uploading = true;
                const formData = new FormData();
                formData.append('pdf', this.selectedPdfFile);
                formData.append('designId', this.currentProduct.design.designId);

                await DesignService.uploadPdf(formData);

                // Aktualisiere die Vorschau nach dem Upload
                await this.designPreviewUrl();

                this.closePdfUploadDialog();
                this.$emit('success', 'PDF wurde erfolgreich hochgeladen');
            } catch (err) {
                this.error = err.message || 'Fehler beim Hochladen der PDF-Datei';
            } finally {
                this.uploading = false;
            }
        },

        getImageDimensions(option) {
            if (option.width && option.height) {
                // Speichere die Dimensionen für späteren Zugriff
                this.templateDimensions = {
                    width: Math.round(option.width),
                    height: Math.round(option.height)
                }
                return `Empfohlene Größe: ${Math.round(option.width)}x${Math.round(option.height)} Pixel`
            }
            return ''
        },

        initCropper() {
            console.log('Initializing cropper...')
            if (this.$refs.cropper) {
                setTimeout(() => {
                    this.cropperInstance = this.$refs.cropper
                    if (this.cropperInstance) {
                        // Lade das Originalbild, um dessen Dimensionen zu erhalten
                        const img = new Image()
                        img.onload = () => {
                            const originalRatio = img.width / img.height
                            console.log(`Setting original aspect ratio: ${originalRatio} (${img.width}x${img.height})`)
                            this.cropperInstance.setAspectRatio(originalRatio)
                            
                            // Setze zusätzliche Cropper-Optionen
                            this.cropperInstance.setData({
                                width: img.width,
                                height: img.height
                            })
                        }
                        img.src = this.previewImageUrl
                    }
                    console.log('Cropper initialized')
                }, 100)
            } else {
                console.error('Cropper reference not found')
            }
        },

        getCropperInstance() {
            return this.$refs.cropper
        },

        cropperRotateLeft() {
            const cropper = this.getCropperInstance()
            if (cropper) {
                cropper.rotate(-90)
            }
        },

        cropperRotateRight() {
            const cropper = this.getCropperInstance()
            if (cropper) {
                cropper.rotate(90)
            }
        },

        cropperFlipH() {
            const cropper = this.getCropperInstance()
            if (cropper) {
                const scaleX = cropper.getData().scaleX || 1
                cropper.scaleX(-scaleX)
            }
        },

        cropperFlipV() {
            const cropper = this.getCropperInstance()
            if (cropper) {
                const scaleY = cropper.getData().scaleY || 1
                cropper.scaleY(-scaleY)
            }
        },

        cropperReset() {
            const cropper = this.getCropperInstance()
            if (cropper) {
                cropper.reset()
            }
        },

        openImagePreview(imageUrl, product, field, aspectRatio) {
            console.log('Opening preview with:', { imageUrl, product, field, aspectRatio })
            
            // Finde die korrekten Dimensionen aus dem Template
            let ratio = 1
            if (product.posterTemplate && product.posterTemplate.options) {
                const option = product.posterTemplate.options.find(opt => opt.field === field)
                if (option) {
                    console.log('Found template option:', option)
                    if (option.width && option.height) {
                        ratio = option.width / option.height
                        console.log(`Calculated aspect ratio from dimensions: ${option.width}x${option.height} = ${ratio}`)
                    }
                }
            }
            
            this.currentImage = this.getProxiedImageUrl(imageUrl)
            this.currentProduct = product
            this.currentField = field
            this.currentAspectRatio = ratio
            this.showImagePreviewDialog = true
            this.previewImageUrl = this.getProxiedImageUrl(imageUrl)
            this.previewMode = 'crop'
            this.imageLoaded = false
            this.cropperInstance = null

            // Initialisiere den Cropper nach dem Dialog geöffnet wurde
            this.$nextTick(() => {
                this.initCropper()
            })
        },

        closeImagePreview() {
            const cropper = this.getCropperInstance()
            if (cropper) {
                cropper.destroy()
            }
            this.showImagePreview = false
            this.currentImage = null
            this.currentProduct = null
            this.currentField = null
            this.imageLoaded = false
            this.cropperInstance = null
        },

        drawPreviewImage() {
            const canvas = this.$refs.previewCanvas
            if (!canvas) return

            const ctx = canvas.getContext('2d')
            const img = new Image()
            img.crossOrigin = 'anonymous'
            img.src = this.getProxiedImageUrl(this.currentImage)

            img.onload = () => {
                // Setze Canvas-Größe
                canvas.width = 1200
                canvas.height = 800

                // Zeichne den gekachelten Hintergrund
                this.drawCheckerPattern(ctx, canvas.width, canvas.height)

                // Berechne die Bildproportionen
                const scale = Math.min(
                    canvas.width / img.naturalWidth,
                    canvas.height / img.naturalHeight
                )

                const scaledWidth = img.naturalWidth * scale
                const scaledHeight = img.naturalHeight * scale

                // Zentriere das Bild
                const x = (canvas.width - scaledWidth) / 2
                const y = (canvas.height - scaledHeight) / 2

                // Zeichne das Bild
                ctx.drawImage(img, x, y, scaledWidth, scaledHeight)
            }
        },

        async saveCrop() {
            const cropper = this.getCropperInstance()
            if (!cropper || !this.currentProduct || !this.currentField) {
                console.error('Missing required data for saving crop')
                return
            }

            try {
                this.saving = true
                const canvas = cropper.getCroppedCanvas({
                    maxWidth: 4096,
                    maxHeight: 4096,
                    fillColor: '#fff',
                    imageSmoothingEnabled: true,
                    imageSmoothingQuality: 'high'
                })

                if (!canvas) {
                    throw new Error('Failed to create canvas')
                }

                const blob = await new Promise((resolve, reject) => {
                    canvas.toBlob((blob) => {
                        if (blob) {
                            resolve(blob)
                        } else {
                            reject(new Error('Failed to create blob'))
                        }
                    }, 'image/jpeg', 0.95)
                })

                const formData = new FormData()
                formData.append('image', blob, 'cropped.jpg')
                formData.append('designId', this.currentProduct.design.designId)
                formData.append('field', this.currentField)

                const response = await DesignService.uploadCroppedImage(formData)
                
                if (response.data && response.data.url) {
                    // Schließe den Dialog
                    this.showImagePreviewDialog = false
                    
                    // Setze den Loading-Status
                    this.loading = true
                    
                    try {
                        // Lade die Bestelldetails neu
                        await this.fetchOrderDetails()
                        this.$emit('success', 'Bild wurde erfolgreich zugeschnitten')
                    } catch (error) {
                        console.error('Error reloading order details:', error)
                        this.error = 'Fehler beim Neuladen der Bestellung'
                    } finally {
                        this.loading = false
                    }
                }

            } catch (error) {
                console.error('Error during crop process:', error)
                this.error = 'Fehler beim Zuschneiden des Bildes'
            } finally {
                this.saving = false
            }
        },

        async reloadDesign() {
            try {
                // Lade das komplette Design neu
                const response = await DesignService.getDesign(this.currentProduct.design.designId)
                if (response.data) {
                    // Aktualisiere die Design-Optionen
                    this.currentProduct.design.options = response.data.options

                    // Warte auf DOM-Update
                    await this.$nextTick()

                    // Initialisiere alle Canvas-Elemente neu
                    if (this.currentProduct.posterTemplate) {
                        this.currentProduct.posterTemplate.options.forEach(option => {
                            if (option.type === 'image') {
                                this.initCanvas(this.currentProduct.design.designId, option.field)
                            }
                        })
                    }

                    // Erzwinge Neurendering der Vorschaubilder
                    await this.designPreviewUrl(this.currentProduct.design.designId)
                }
            } catch (error) {
                console.error('Error reloading design:', error)
            }
        },

        watch: {
            previewMode(newMode) {
                if (newMode === 'preview') {
                    this.$nextTick(() => {
                        this.drawPreviewImage()
                    })
                }
            }
        },

        onCropperReady() {
            console.log('Cropper is ready');
            this.imageLoaded = true;
        },

        onCropperError(error) {
            console.error('Cropper error:', error)
            this.error = 'Fehler beim Laden des Croppers: ' + error.message
        },

        // Erweitere handleImageUpload
        async handleImageUpload(file, field, product) {
            if (!file) return;

            try {
                this.loading = true;
                const imageUrl = URL.createObjectURL(file);
                product.design.options[field] = imageUrl;

                // Zeichne das neue Bild
                this.$nextTick(() => {
                    this.drawImageOnCanvas(imageUrl, 'canvas-' + field, 300, 300);
                });

                await this.saveChanges(product.design.options);
            } catch (err) {
                this.error = err.message || 'Fehler beim Hochladen des Bildes';
            } finally {
                this.loading = false;
            }
        },

        async removeImage(field, product) {
            try {
                this.loading = true;
                product.design.options[field] = null;
                // Speichere die Änderungen
                await this.saveChanges(product.design.options);
                this.$emit('success', 'Bild wurde erfolgreich entfernt');
            } catch (err) {
                this.error = err.message || 'Fehler beim Entfernen des Bildes';
            } finally {
                this.loading = false;
            }
        },

        copyImageUrl() {
            navigator.clipboard.writeText(this.selectedImage)
                .then(() => {
                    this.$emit('success', 'URL wurde in die Zwischenablage kopiert');
                })
                .catch(() => {
                    this.error = 'Fehler beim Kopieren der URL';
                });
        },

        getCanvasId(designId, field) {
            return `canvas-${designId}-${field}`;
        },

        getImageId(designId, field) {
            return `img-${designId}-${field}`;
        },

        initCanvas(designId, field) {
            const canvas = document.getElementById(this.getCanvasId(designId, field))
            const img = document.getElementById(this.getImageId(designId, field))

            if (!canvas || !img) {
                console.warn('Canvas or image not found for:', { designId, field })
                return
            }

            const ctx = canvas.getContext('2d')
            if (!ctx) {
                console.warn('Could not get canvas context for:', { designId, field })
                return
            }

            const wrapper = canvas.parentElement
            if (!wrapper) {
                console.warn('Could not find wrapper element for:', { designId, field })
                return
            }

            canvas.width = wrapper.offsetWidth
            canvas.height = wrapper.offsetHeight

            // Zeichne zuerst den weißen Hintergrund
            ctx.fillStyle = '#ffffff'
            ctx.fillRect(0, 0, canvas.width, canvas.height)

            // Dann den gekachelten Hintergrund
            this.drawCheckerPattern(ctx, canvas.width, canvas.height)

            if (!img.complete) {
                img.onload = () => this.drawCanvasContent(ctx, img, canvas)
            } else {
                this.drawCanvasContent(ctx, img, canvas)
            }
        },

        drawCanvasContent(ctx, img, canvas) {
            try {
                const scale = Math.min(
                    canvas.width / img.naturalWidth,
                    canvas.height / img.naturalHeight
                )

                const scaledWidth = img.naturalWidth * scale
                const scaledHeight = img.naturalHeight * scale

                const x = (canvas.width - scaledWidth) / 2
                const y = (canvas.height - scaledHeight) / 2

                // Zeichne das Bild mit weißem Hintergrund
                ctx.fillStyle = '#ffffff'
                ctx.fillRect(x, y, scaledWidth, scaledHeight)
                ctx.drawImage(img, x, y, scaledWidth, scaledHeight)

                // Zeichne den Rahmen
                ctx.strokeStyle = '#00A0FF'
                ctx.lineWidth = 2
                ctx.strokeRect(x, y, scaledWidth, scaledHeight)
            } catch (error) {
                console.error('Error drawing canvas content:', error)
            }
        },

        drawCheckerPattern(ctx, width, height) {
            try {
                const squareSize = 20;
                ctx.fillStyle = '#808080';
                ctx.fillRect(0, 0, width, height);

                ctx.fillStyle = '#666666';
                for (let x = 0; x < width; x += squareSize * 2) {
                    for (let y = 0; y < height; y += squareSize * 2) {
                        ctx.fillRect(x, y, squareSize, squareSize);
                        ctx.fillRect(x + squareSize, y + squareSize, squareSize, squareSize);
                    }
                }
            } catch (error) {
                console.error('Error drawing checker pattern:', error);
            }
        },

        // Neue Methode zum Proxyen der Bilder
        getProxiedImageUrl(url) {
            if (!url) return ''
            // Wenn die URL bereits eine Proxy-URL ist, nicht nochmal umwandeln
            if (url.startsWith('/public/images/proxy/')) return url
            // Wenn die URL bereits eine relative URL ist, verwende sie direkt
            if (url.startsWith('/')) return url
            
            // Extrahiere den Dateinamen aus der S3-URL
            const filename = url.split('/').pop()
            // Verwende unseren eigenen Server als Proxy
            return `/public/images/proxy/${(filename)}`
        },

        async analyzeTransparency(product) {
            if (!product.design || !product.design.designId) return;

            try {
                // Setze Flag für Loading-Status
                this.$set(product, 'analyzingTransparency', true);

                // Rufe die Analyse-API auf
                const response = await DesignService.analyzeTransparency(product.design.designId);
                
                // Aktualisiere die Design-Optionen mit den Analyseergebnissen
                if (response.data) {
                    // Füge Timestamps zu allen URLs hinzu
                    const timestamp = Date.now();
                    const transparencyData = response.data;
                    Object.keys(transparencyData).forEach(key => {
                        if (transparencyData[key].url) {
                            transparencyData[key].url = `${transparencyData[key].url}?v=${timestamp}`;
                        }
                        if (transparencyData[key].url_cropped) {
                            transparencyData[key].url_cropped = `${transparencyData[key].url_cropped}?v=${timestamp}`;
                        }
                    });
                    this.$set(product.design.options, 'transparencyData', transparencyData);
                }

                this.$emit('success', 'Transparenzanalyse erfolgreich durchgeführt');
            } catch (error) {
                this.error = 'Fehler bei der Transparenzanalyse: ' + (error.message || 'Unbekannter Fehler');
            } finally {
                this.$set(product, 'analyzingTransparency', false);
            }
        },

        getTransparencyColor(data) {
            if (!data) return 'grey';
            const percentage = data.transparencyPercentage || 0;
            if (percentage > 50) return 'error';
            if (percentage > 20) return 'warning';
            return 'success';
        },

        getTransparencyPercentage(data) {
            if (!data) return 0;
            
            // Berechne den Durchschnitt aller Transparenzwerte
            const values = Object.values(data);
            if (values.length === 0) return 0;
            
            const sum = values.reduce((acc, curr) => acc + (curr.transparencyPercentage || 0), 0);
            return (sum / values.length).toFixed(4);
        },

        previewCroppedImage(url) {
            // Öffne das Bild in einem neuen Tab
            window.open(this.getProxiedImageUrl(url), '_blank');
        },

        openImagePreviewDialog(imageUrl) {
            this.previewImageUrl = this.getProxiedImageUrl(imageUrl);
            this.showImagePreviewDialog = true;
        },

        closeImagePreviewDialog() {
            this.showImagePreviewDialog = false;
            this.previewImageUrl = null;
        },

        openTransparencyPreview(imageUrl, field, transparencyData) {
            // Erstelle einen temporären Canvas für die Vorschau
            const tempCanvas = document.createElement('canvas');
            const ctx = tempCanvas.getContext('2d');
            const image = new Image();

            image.onload = () => {
                // Canvas-Größe an Bildgröße anpassen
                tempCanvas.width = image.width;
                tempCanvas.height = image.height;

                // Bild zeichnen
                ctx.drawImage(image, 0, 0);

                // Transparente Bereiche als Polygone zeichnen
                if (transparencyData.transparencyPolygons) {
                    ctx.strokeStyle = 'rgba(255, 0, 0, 0.8)';
                    ctx.lineWidth = 2;
                    ctx.fillStyle = 'rgba(255, 0, 0, 0.2)';

                    transparencyData.transparencyPolygons.forEach(polygon => {
                        if (polygon.length < 3) return;

                        ctx.beginPath();
                        ctx.moveTo(polygon[0].x, polygon[0].y);
                        
                        for (let i = 1; i < polygon.length; i++) {
                            ctx.lineTo(polygon[i].x, polygon[i].y);
                        }
                        
                        ctx.closePath();
                        ctx.fill();
                        ctx.stroke();
                    });
                }

                // Konvertiere den Canvas in eine Data-URL und zeige sie im Dialog
                this.previewImageUrl = tempCanvas.toDataURL();
                this.showImagePreviewDialog = true;
            };

            // Füge Timestamp zum Bild-URL hinzu
            const timestampedUrl = imageUrl.includes('?v=') ? imageUrl : `${imageUrl}?v=${this.timestamp}`;
            image.src = this.getProxiedImageUrl(timestampedUrl);
        },

        async initTransparencyVisualization(field, transparencyData) {
            if (!field || !transparencyData) return;

            const canvas = this.$refs['transparencyCanvas-' + field]?.[0];
            if (!canvas) return;

            const ctx = canvas.getContext('2d');
            const image = new Image();

            image.onload = () => {
                // Canvas-Größe an Container anpassen
                const container = canvas.parentElement;
                const containerWidth = container.offsetWidth;
                const containerHeight = container.offsetHeight;
                
                // Setze Canvas-Dimensionen
                canvas.width = containerWidth;
                canvas.height = containerHeight;

                // Berechne Skalierungsfaktor
                const scale = Math.min(
                    containerWidth / image.width,
                    containerHeight / image.height
                );

                // Berechne zentrierte Position
                const x = (containerWidth - image.width * scale) / 2;
                const y = (containerHeight - image.height * scale) / 2;

                // Zeichne das Bild
                ctx.drawImage(image, x, y, image.width * scale, image.height * scale);

                // Skaliere und zeichne die Polygone
                if (transparencyData.transparencyPolygons) {
                    ctx.strokeStyle = 'rgba(255, 0, 0, 0.8)';
                    ctx.lineWidth = 2;
                    ctx.fillStyle = 'rgba(255, 0, 0, 0.2)';

                    transparencyData.transparencyPolygons.forEach(polygon => {
                        if (polygon.length < 3) return;

                        ctx.beginPath();
                        ctx.moveTo(
                            x + polygon[0].x * scale,
                            y + polygon[0].y * scale
                        );
                        
                        for (let i = 1; i < polygon.length; i++) {
                            ctx.lineTo(
                                x + polygon[i].x * scale,
                                y + polygon[i].y * scale
                            );
                        }
                        
                        ctx.closePath();
                        ctx.fill();
                        ctx.stroke();
                    });
                }
            };

            // Füge Timestamp zum Bild-URL hinzu
            const timestampedUrl = transparencyData.url.includes('?v=') ? transparencyData.url : `${transparencyData.url}?v=${this.timestamp}`;
            image.src = this.getProxiedImageUrl(timestampedUrl);
        },

        async applyTransparencyCrop(product, field, croppedUrl) {
            if (!product.design || !product.design.designId || !croppedUrl) return;

            try {
                this.$set(product, 'applyingCrop', true);

                // Lade das Bild von der URL
                const response = await fetch(this.getProxiedImageUrl(croppedUrl));
                const blob = await response.blob();

                // Erstelle FormData mit dem Bild
                const formData = new FormData();
                formData.append('image', blob, 'cropped.jpg');
                formData.append('designId', product.design.designId);
                formData.append('field', field);

                // Lade das zugeschnittene Bild hoch
                const uploadResponse = await DesignService.uploadCroppedImage(formData);
                
                if (uploadResponse.data) {
                    // Aktualisiere das Design
                    await this.reloadDesign();

                    // Aktualisiere das Vorschaubild mit Cache-Busting
                    const timestamp = Date.now();
                    const randomString = Math.random().toString(36).substring(7);
                    product.design.options.poster = `${product.design.options.poster}?v=${timestamp}-${randomString}`;

                    this.$emit('success', 'Zugeschnittene Version wurde übernommen');
                }
            } catch (error) {
                this.error = 'Fehler beim Übernehmen der zugeschnittenen Version: ' + (error.message || 'Unbekannter Fehler');
            } finally {
                this.$set(product, 'applyingCrop', false);
            }
        },

        hasAnyCroppedImages(product) {
            if (!product.design?.options?.transparencyData) return false;
            return Object.values(product.design.options.transparencyData).some(data => data.url_cropped);
        },

        async applyAllCrops(product) {
            if (!product.design?.options?.transparencyData) return;

            try {
                this.$set(product, 'applyingAllCrops', true);
                
                // Sammle alle Felder mit zugeschnittenen Bildern
                const croppedFields = Object.entries(product.design.options.transparencyData)
                    .filter(([, data]) => data.url_cropped)
                    .map(([field, data]) => ({ field, url: data.url_cropped }));

                // Wende nacheinander alle Crops an
                for (const { field, url } of croppedFields) {
                    await this.applyTransparencyCrop(product, field, url);
                }

                this.$emit('success', 'Alle zugeschnittenen Versionen wurden übernommen');
            } catch (error) {
                this.error = 'Fehler beim Übernehmen aller zugeschnittenen Versionen: ' + (error.message || 'Unbekannter Fehler');
            } finally {
                this.$set(product, 'applyingAllCrops', false);
            }
        },

        getOriginalImages(product) {
            const originalImages = {};
            if (product.attributes) {
                Object.entries(product.attributes).forEach(([key, value]) => {
                    if (key.endsWith('_original')) {
                        originalImages[key.replace('_original', '')] = value;
                    }
                });
            }
            return originalImages;
        },

        openOriginalImage(imageUrl, product, field) {
            this.openImagePreview(imageUrl, product, field);
        },
    }
}
</script>
