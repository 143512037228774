<template>
  <div>
    <v-container>
      <!-- Error Alert -->
      <v-alert type="error" v-if="error" :value="true" class="mb-4">{{ error }}</v-alert>

      <!-- Filter Card -->
      <v-card class="mb-6">
        <v-card-title class="d-flex align-center">
          <v-icon left>mdi-filter-variant</v-icon>
          Filter & Sortierung
        </v-card-title>
        <v-card-text>
          <v-row>
            <!-- Date Range -->
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="6">
                  <v-menu
                    v-model="dateMenu1"
                    :close-on-content-click="false"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateFrom"
                        label="Datum von"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        dense
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateFrom"
                      no-title
                      @input="dateMenu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    v-model="dateMenu2"
                    :close-on-content-click="false"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateTo"
                        label="Datum bis"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        dense
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateTo"
                      no-title
                      @input="dateMenu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>

            <!-- Order ID Filter -->
            <v-col cols="12" md="3">
              <v-text-field
                v-model="orderIdFilter"
                label="Bestellnummer"
                clearable
                @input="debouncedFetchOrders"
                prepend-icon="mdi-pound"
                hide-details
                dense
                outlined
              ></v-text-field>
            </v-col>

            <!-- Status Filter -->
            <v-col cols="12" md="3">
              <v-select
                v-model="checkedFilter"
                :items="[
                  { text: 'Alle Bestellungen', value: 'all' },
                  { text: 'Kontrolliert', value: 'checked' },
                  { text: 'Nicht kontrolliert', value: 'unchecked' }
                ]"
                label="Kontrollstatus"
                @change="fetchOrders"
                prepend-icon="mdi-check-circle"
                hide-details
                dense
                outlined
              ></v-select>
            </v-col>

            <!-- Shipping Status Filter -->
            <v-col cols="12" md="3">
              <v-select
                v-model="shippedFilter"
                :items="[
                  { text: 'Alle Lieferstatus', value: 'all' },
                  { text: 'Versendet', value: 'shipped' },
                  { text: 'Nicht versendet', value: 'unshipped' }
                ]"
                label="Lieferstatus"
                @change="fetchOrders"
                prepend-icon="mdi-truck-delivery"
                hide-details
                dense
                outlined
              ></v-select>
            </v-col>

            <!-- Transparency Filter -->
            <v-col cols="12" md="3">
              <v-select
                v-model="transparencyFilter"
                :items="[
                  { text: 'Alle Transparenzen', value: 'all' },
                  { text: 'Mit Transparenzen', value: 'with' },
                  { text: 'Ohne Transparenzen', value: 'without' }
                ]"
                label="Transparenzen"
                @change="fetchOrders"
                prepend-icon="mdi-image-filter"
                hide-details
                dense
                outlined
              ></v-select>
            </v-col>

            <!-- Sort Direction -->
            <v-col cols="12" md="3">
              <v-select
                v-model="sortDirection"
                :items="[
                  { text: 'Neueste zuerst', value: 'desc' },
                  { text: 'Älteste zuerst', value: 'asc' }
                ]"
                label="Sortierung"
                @change="fetchOrders"
                prepend-icon="mdi-sort"
                hide-details
                dense
                outlined
              ></v-select>
            </v-col>
          </v-row>

          <!-- Filter Actions -->
          <v-row class="mt-4">
            <v-col cols="12">
              <v-btn color="primary" @click="fetchOrders" class="mr-2">
                <v-icon left>mdi-filter</v-icon>
                Filter anwenden
              </v-btn>
              <v-btn text @click="resetFilters" class="mr-2">
                <v-icon left>mdi-refresh</v-icon>
                Filter zurücksetzen
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Orders List -->
      <v-row>
        <v-col v-for="order in paginatedOrders" :key="order.orderId" cols="12">
          <v-card :loading="loading" :class="{'border-left-success': order.isChecked}" class="order-card">
            <v-card-title class="d-flex justify-space-between py-2">
              <div class="d-flex align-center">
                <v-chip
                  :color="order.isChecked ? 'success' : 'grey'"
                  small
                  class="mr-2"
                >
                  {{ order.isChecked ? 'Kontrolliert' : 'Nicht kontrolliert' }}
                </v-chip>
                <span class="text-h6">Bestellung #{{ order.orderId }}</span>
              </div>
              <div>
                <v-btn
                  :color="order.isChecked ? 'success' : 'grey'"
                  @click="toggleCheckedStatus(order)"
                  text
                  class="ml-2"
                >
                  <v-icon left>{{ order.isChecked ? 'mdi-check-circle' : 'mdi-checkbox-blank-circle-outline' }}</v-icon>
                  {{ order.isChecked ? 'Kontrolliert' : 'Nicht kontrolliert' }}
                </v-btn>
              </div>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text class="py-3">
              <!-- Customer Info -->
              <v-row no-gutters class="mb-3">
                <v-col cols="12" md="6">
                  <div class="d-flex align-center mb-2">
                    <v-icon small class="mr-2">mdi-account</v-icon>
                    <strong>{{ order.customer.firstName }} {{ order.customer.lastName }}</strong>
                  </div>
                  <div class="d-flex align-center mb-2">
                    <v-icon small class="mr-2">mdi-map-marker</v-icon>
                    <span>{{ order.customer.adress }}, {{ order.customer.zip }} {{ order.customer.city }}, {{ order.customer.country }}</span>
                  </div>
                  <div class="d-flex align-center">
                    <v-icon small class="mr-2">mdi-clock-outline</v-icon>
                    <span>{{ new Date(order.created).toLocaleString() }}</span>
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="d-flex justify-end align-center">
                  <v-chip :color="getStateColor(order.state)" small>{{ order.state }}</v-chip>
                </v-col>
              </v-row>

              <!-- Products -->
              <div v-for="product in order.products" :key="product.design.designId" class="mt-4">
                <v-row>
                  <v-col cols="12" md="4">
                    <v-img
                      :src="product.design.poster"
                      contain
                      height="200"
                      class="rounded-lg"
                    >
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>
                  <v-col cols="12" md="8">
                    <h3 class="text-subtitle-1 font-weight-bold mb-2">{{ product.title }}</h3>
                    <v-row>
                      <v-col cols="12">
                        <!-- Wichtige Attribute in Chips -->
                        <div class="d-flex flex-wrap align-center mb-3">
                          <v-chip
                            small
                            class="mr-2 mb-2"
                            color="primary"
                            outlined
                          >
                            <v-icon left small>mdi-package-variant</v-icon>
                            {{ product.sku }}
                          </v-chip>
                          <v-chip
                            small
                            class="mr-2 mb-2"
                            color="secondary"
                            outlined
                          >
                            <v-icon left small>mdi-numeric</v-icon>
                            Menge: {{ product.qty }}
                          </v-chip>
                          <!-- Transparenz Status -->
                          <v-chip
                            v-if="product.design"
                            small
                            class="mr-2 mb-2"
                            :color="getTransparencyColor(product.design)"
                            outlined
                          >
                            <v-icon left small>{{ getTransparencyIcon(product.design) }}</v-icon>
                            <span v-if="!product.design.transparencyAnalyzed">Wird analysiert...</span>
                            <span v-else>
                              {{ product.design.hasTransparencies ? 'Transparenzen' : 'Keine Transparenzen' }}
                            </span>
                            <v-icon
                              v-if="product.design.hasTransparencies"
                              right
                              small
                              color="warning"
                            >
                              mdi-alert-circle
                            </v-icon>
                          </v-chip>
                        </div>

                        <!-- Attribute Grid -->
                        <v-row dense>
                          <v-col 
                            v-for="(value, key) in getFilteredAttributes(product.attributes)" 
                            :key="key"
                            cols="12" 
                            sm="6" 
                            md="4" 
                            lg="3"
                          >
                            <div class="attribute-item pa-2">
                              <small class="text-caption grey--text">{{ formatAttributeKey(key) }}</small>
                              <div v-if="isImageUrl(value)" class="text-center">
                                <v-img
                                  :src="value"
                                  contain
                                  height="100"
                                  class="rounded-lg"
                                >
                                  <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                      <v-progress-circular indeterminate></v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                                <v-btn
                                  x-small
                                  text
                                  color="primary"
                                  class="mt-1"
                                  :href="value"
                                  target="_blank"
                                >
                                  <v-icon small left>mdi-open-in-new</v-icon>
                                  Öffnen
                                </v-btn>
                              </div>
                              <div v-else class="text-body-2 text-truncate">{{ value }}</div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="pa-4">
              <v-btn
                color="primary"
                :href="`https://svg.us.accelery.host/order/configurator?orderId=${order.orderId}`"
                target="_blank"
              >
                <v-icon left>mdi-pencil</v-icon>
                Bearbeiten
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <!-- Pagination -->
      <v-row justify="center" class="mt-6">
        <v-col cols="8">
          <v-pagination
            v-model="currentPage"
            :length="totalPages"
            :total-visible="7"
            @input="fetchOrders"
          ></v-pagination>
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="itemsPerPage"
            :items="[20, 50, 100]"
            label="Einträge pro Seite"
            @change="handleItemsPerPageChange"
            hide-details
            dense
            outlined
          ></v-select>
        </v-col>
      </v-row>
    </v-container>

    <AuthCheck />
  </div>
</template>

<script>
import DesignService from "../../services/DesignService";
import AuthCheck from "../../components/AuthCheck.vue";
import AuthService from "../../services/AuthService";
import _ from 'lodash';

export default {
  components: { AuthCheck },
  data: () => ({
    designs: [],
    paginatedOrders: [],
    loading: false,
    error: null,
    user: null,
    selectedOrder: null,
    // Pagination
    currentPage: 1,
    itemsPerPage: 20,
    totalItems: 0,
    totalPages: 0,
    // Filter
    dateFrom: null,
    dateTo: null,
    dateMenu1: false,
    dateMenu2: false,
    orderIdFilter: '',
    sortDirection: 'desc',
    checkedFilter: 'all',
    shippedFilter: 'all',
    transparencyFilter: 'all',
  }),

  async created() {
    try {
      this.user = (await AuthService.getUser()).data;
      this.debouncedFetchOrders = _.debounce(this.fetchOrders, 500);
      await this.fetchOrders();
    } catch (err) {
      console.error(err);
      if (!this.user) this.$router.push("/auth/login");
    }
  },

  methods: {
    getStateColor(state) {
      const colors = {
        'UNSHIPPED': 'warning',
        'SHIPPED': 'success',
        'CANCELLED': 'error',
        'PENDING': 'info'
      };
      return colors[state] || 'grey';
    },

    getTransparencyIcon(design) {
      if (!design.transparencyAnalyzed) return 'mdi-progress-clock';
      return design.hasTransparencies ? 'mdi-alert' : 'mdi-check-circle';
    },

    getTransparencyColor(design) {
      if (!design.transparencyAnalyzed) return 'grey';
      return design.hasTransparencies ? 'warning' : 'success';
    },

    isImageUrl(value) {
      if (typeof value !== 'string') return false;
      const trimmedValue = value.trim().toLowerCase();
      return (
        trimmedValue.startsWith('http') && 
        (
          trimmedValue.endsWith('.jpg') ||
          trimmedValue.endsWith('.jpeg') ||
          trimmedValue.endsWith('.png') ||
          trimmedValue.endsWith('.gif') ||
          trimmedValue.endsWith('.webp') ||
          trimmedValue.endsWith('.svg')
        )
      );
    },

    getFilteredAttributes(attributes) {
      // Attribute die wir ausschließen wollen, da sie anders dargestellt werden
      const excludeKeys = ['designId', 'Color', '_Cost'];
      
      // Filtere die Attribute
      return Object.keys(attributes).reduce((filtered, key) => {
        if (!excludeKeys.some(exclude => key.includes(exclude)) && attributes[key]) {
          filtered[key] = attributes[key];
        }
        return filtered;
      }, {});
    },

    formatAttributeKey(key) {
      // Entferne _Cost Suffix und formatiere den Key
      return key
        .replace(/_Cost$/, '')
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    },

    async fetchOrders() {
      this.loading = true;
      try {
        const response = await DesignService.getOrdersExtended({
          page: this.currentPage,
          limit: this.itemsPerPage,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          orderId: this.orderIdFilter,
          sort: this.sortDirection,
          checked: this.checkedFilter,
          shipped: this.shippedFilter,
          transparency: this.transparencyFilter
        });
        
        this.paginatedOrders = response.data.orders;
        this.totalItems = response.data.pagination.total;
        this.totalPages = response.data.pagination.pages;
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    },

    showItem(order) {
      this.selectedOrder = order;
    },

    async generateOrder(designId) {
      this.loading = true;
      try {
        await DesignService.generateOrder(designId);
        if (this.selectedOrder) {
          const updatedOrder = await DesignService.getOrder(this.selectedOrder.orderId);
          this.selectedOrder = updatedOrder.data;
        }
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    },

    resetFilters() {
      this.dateFrom = null;
      this.dateTo = null;
      this.orderIdFilter = '';
      this.sortDirection = 'desc';
      this.checkedFilter = 'all';
      this.shippedFilter = 'all';
      this.transparencyFilter = 'all';
      this.currentPage = 1;
      this.fetchOrders();
    },

    handleItemsPerPageChange() {
      this.currentPage = 1;
      this.fetchOrders();
    },

    async toggleCheckedStatus(order) {
      this.loading = true;
      try {
        await DesignService.updateOrderCheckedStatus(order.orderId, !order.isChecked);
        order.isChecked = !order.isChecked;
        await this.fetchOrders(); 
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    },
  }
};
</script>

<style scoped>
.order-card {
  transition: all 0.3s ease;
}

.order-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.border-left-success {
  border-left: 4px solid var(--v-success-base) !important;
}

.v-card__title {
  word-break: break-word;
}

.v-list-item__subtitle {
  white-space: normal;
  word-break: break-word;
}

.attribute-item {
  background-color: var(--v-secondary-lighten5);
  border-radius: 4px;
  height: 100%;
  transition: background-color 0.2s ease;
}

.attribute-item:hover {
  background-color: var(--v-secondary-lighten4);
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>