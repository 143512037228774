//Poster service
//This service is responsible for all poster related requests
import Api from '@/services/Api'

export default {
    /**
     * Get all design
     * @returns {Promise}
     **/
    getDesigns(params = {}) {
        return Api().get('designs', {
          params: {
            page: params.page,
            limit: params.limit,
            dateFrom: params.dateFrom,
            dateTo: params.dateTo,
            sku: params.sku,
            sort: params.sort
          }
        })
      },

    /**
     * Get poster template by SKU
     * @param {String} sku 
     * @returns {Promise}
     */
    getPosterTemplate(sku) {
        return Api().get(`designs/poster/${encodeURIComponent(sku)}`);
    },

    /**
     * Delete a design
     * 
     * @param {String} id
     * @returns {Promise}
     **/
    deleteDesign(id) {
        return Api().delete(`designs/${id}`)
    },


    /**
     * Get Orders
     **/
    getOrders(params = {}) {
        return Api().get('orders', {
            params: {
                page: params.page,
                limit: params.limit,
                dateFrom: params.dateFrom,
                dateTo: params.dateTo,
                orderId: params.orderId,
                sort: params.sortDirection,
                checked: params.checked
            }
        })
    },

    /**
     * Get order by Id
     */
    getOrder(orderId) {
        return Api().get(`orders/${encodeURIComponent(orderId)}`)
    },

    getDesign(designId) {
        return Api().get(`designs/${encodeURIComponent(designId)}`);
    },

    saveDesign(designId, design) {
        return Api().put(`designs/${encodeURIComponent(designId)}`, design);
    },

    importOrder(orderId) {
        return Api().post(`orders/${encodeURIComponent(orderId)}/import`)
    },


    /**
     * Generate Order
     */
    generateOrder(designId) {
        return Api().post(`orders/generate/${encodeURIComponent(designId)}`)
    },

    async updateOrderCheckedStatus(orderId, isChecked) {
        return Api().put(`orders/${orderId}/check`, { isChecked });
    },

    /**
     * Upload PDF für ein Design
     * @param {FormData} formData - FormData mit pdf und designId
     * @returns {Promise}
     */
    uploadPdf(formData) {
        return Api().post('designs/upload-pdf', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    /**
     * Upload cropped image for a design
     * @param {FormData} formData - FormData containing the cropped image, designId, and field
     * @returns {Promise}
     */
    uploadCroppedImage(formData) {
        return Api().post('designs/upload-cropped', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    /**
     * Analysiert die Transparenzen eines Designs
     * @param {string} designId - Die ID des zu analysierenden Designs
     * @returns {Promise} - Promise mit den Analyseergebnissen
     */
    analyzeTransparency(designId) {
        return Api().post(`designs/${designId}/analyze-transparency`);
    },

    getOrdersExtended(params) {
      return Api().get('orders-extended', { params });
    },

}